import { list, canList } from "./schemaApi";
import { getEntityByte, getQueryField } from "./schemaProvider";
import { equal } from "@/services/filtering";

export async function getCustomFields(entityKey) {
    if (!canList(entityKey) || getQueryField(entityKey, "customFields") == null) {
        return [];
    }

    const entityByte = getEntityByte(entityKey);
    const filter = equal("entity", entityByte, "byte");
    const listItems = await list("customField", { filter: filter });
    return listItems.items;
}
