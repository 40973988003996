<template>
    <v-hover v-slot="{ hover }">
        <div class="d-flex align-center editable-header">
            <div v-if="!isRenaming" class="text-truncate mr-1" :class="{ 'hover-header': hover }">
                {{ header.text }}
            </div>
            <app-dense-text-field
                v-if="isRenaming"
                v-model="renamedHeader"
                ref="renameInput"
                @blur="updateHeaderName"
                @keyup.enter="updateHeaderName"
                style="min-width: 100px"
            />
            <v-menu v-model="menu" :close-on-content-click="false" offset-x>
                <template #activator="{ on, attrs }">
                    <v-fade-transition>
                        <v-icon v-show="hover" small class="flex-shrink-0" v-bind="attrs" v-on="on">
                            mdi-dots-vertical
                        </v-icon>
                    </v-fade-transition>
                </template>
                <v-list flat dense style="padding: 0px">
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, index) in menuItems"
                            :key="index"
                            :disabled="item.disabled()"
                            @click="item.click()"
                        >
                            <v-list-item-icon class="mr-2">
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </div>
    </v-hover>
</template>

<script>
    import { update, delete_, canEdit, canDelete } from "@/features/schemas/services/schemaApi";
    import { getEnum } from "@/features/schemas/services/schemaProvider";
    import { isNullOrWhiteSpace } from "@/services/stringUtility";

    export default {
        props: {
            entityKey: {
                type: String,
                required: true,
            },
            header: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                menu: false,
                isRenaming: false,
                renamedHeader: null,
                menuItems: [
                    {
                        icon: "mdi-pencil",
                        title: "Rename",
                        click: this.renameHeader,
                        disabled: () => !canEdit("customField"),
                    },
                    {
                        icon: "mdi-delete",
                        title: "Delete",
                        click: this.deleteHeader,
                        disabled: () => !canDelete(this.entityKey) || !canDelete("customField"),
                    },
                ],
            };
        },
        methods: {
            renameHeader() {
                this.renamedHeader = this.header.text;
                this.isRenaming = true;
                this.reset();
                this.$nextTick(() => {
                    if (this.$refs.renameInput) {
                        this.$refs.renameInput.$children[0].focus();
                    }
                });
            },
            async updateHeaderName() {
                try {
                    if (this.renamedHeader == this.header.text) return;
                    const newName = this.renamedHeader.trim();
                    if (isNullOrWhiteSpace(newName)) {
                        return;
                    }

                    const entityByte = getEnum("entity")[this.entityKey];
                    const item = { id: this.header.id, entity: entityByte, name: this.renamedHeader };
                    await update("customField", item);
                    this.$emit("update", item);
                } finally {
                    this.isRenaming = false;
                    this.renamedHeader = null;
                }
            },
            async deleteHeader() {
                try {
                    const confirmMessage =
                        "Are you sure you want to delete this column? All the values will be removed permanently";
                    if (!confirm(confirmMessage)) return;
                    await delete_("customField", this.header.id);
                    this.$emit("delete", this.header.id);
                } finally {
                    this.reset();
                }
            },
            reset() {
                this.menu = false;
                this.selectedItem = null;
            },
        },
    };
</script>

<style scoped>
    .editable-header {
        width: 100%;
        overflow: hidden;
    }

    .hover-header {
        color: black;
        font-weight: bold;
    }
</style>
