<template>
    <v-overlay v-bind="$attrs" :z-index="zIndex" :opacity="opacity" :value="loading">
        <div :class="customClass ?? 'loading'">
            <slot>Loading</slot>
            <slot name="progress">
                <v-progress-linear color="primary" height="10" indeterminate rounded striped></v-progress-linear>
            </slot>
        </div>
    </v-overlay>
</template>

<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            zIndex: {
                type: Number,
                default: 1,
            },
            opacity: {
                type: Number,
                default: 0,
            },
            customClass: {
                type: String,
                default: null,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .loading {
        color: black;
        text-align: center;
        width: 300px;
    }
</style>
