<template>
    <div>
        <v-row class="actions">
            <v-col class="col-auto pa-1">
                <app-button
                    large
                    :color="selectedMode === interactionModeEnum.Edit ? 'blue' : 'black'"
                    outlined
                    @click="setMode(interactionModeEnum.Edit)"
                >
                    <v-icon>mdi-pencil</v-icon>
                </app-button>
            </v-col>
            <v-col class="col-auto pa-1">
                <app-button
                    large
                    :color="selectedMode === interactionModeEnum.Delete ? 'red' : 'black'"
                    outlined
                    @click="setMode(interactionModeEnum.Delete)"
                >
                    <v-icon>mdi-delete</v-icon>
                </app-button>
            </v-col>
            <v-col class="col-auto pa-1">
                <app-button
                    large
                    :color="selectedMode === interactionModeEnum.Region ? 'primary' : 'black'"
                    outlined
                    @click="setMode(interactionModeEnum.Region)"
                >
                    <v-icon>mdi-select</v-icon>
                </app-button>
            </v-col>
            <v-spacer />
            <v-col class="col-auto pa-1">
                <app-button color="primary" outlined large @click="openDialog()">Select Category</app-button>
            </v-col>
        </v-row>

        <v-row cols="auto" no-gutters class="detonators px-0 my-2 flex-nowrap">
            <v-col v-for="item in detonators" :key="item.id" cols="auto" class="px-1 py-0 my-0">
                <v-btn
                    text
                    class="detonator-btn"
                    @click="onDetonatorClick(item)"
                    :class="{
                        selected: isSelected(item.id),
                        used: isUsed(item.id),
                    }"
                >
                    <div class="detonator">
                        <exm-icon v-if="item.icon" :icon="item.icon" width="72px" height="72px" />
                        <div class="name">{{ item.name }}</div>
                    </div>
                </v-btn>
            </v-col>
            <div v-if="detonators.length === 0" class="v-center py-6" style="width: 100%; height: 100%">
                <div class="text-center">
                    <v-icon>mdi-alert-circle</v-icon>
                    <div>Please select a category to add detonators to the grid</div>
                </div>
            </div>
        </v-row>

        <!-- Category Selection dialog -->
        <app-dialog :value="categoryDialog" :persistent="true" :overlay="true">
            <v-card>
                <v-card-title>Select Detonator Category</v-card-title>
                <v-card-text class="pt-2">
                    <div v-if="detonatorCategories.length > 0"></div>
                    <v-row v-for="cat in detonatorCategories" :key="cat.id" class="d-flex align-center pa-2">
                        <v-btn
                            rounded
                            class="category-btn"
                            :class="{
                                selected: isSelectedCategory(cat.id),
                                used: isCategoryUsed(cat.id),
                            }"
                            @click="onCategoryChange(cat)"
                            elevation="2"
                        >
                            <div class="category-content">
                                <exm-icon v-if="cat.icon" :icon="cat.icon" width="32px" height="32px" />
                                <div class="category-label">{{ cat.name }}</div>
                            </div>
                        </v-btn>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <app-button text @click="categoryDialog = false">Cancel</app-button>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </app-dialog>
    </div>
</template>

<script>
    import { InteractionMode } from "face-sheet-grid";
    import ExmIcon from "@/components/ExmIcon";

    export default {
        name: "DetonatorControl",
        components: { ExmIcon },
        props: {
            selectedDetonator: {
                type: Object,
                default: null,
            },
            selectedCategory: {
                type: Object,
                default: null,
            },
            detonatorCategories: {
                type: Array,
                required: true,
            },
            magazineItems: {
                type: Array,
                required: true,
            },
            selectedMode: {
                type: Number,
            },
            usedMagazineItemIds: {
                type: Array,
                default: () => [],
            },
            usedMagazineItemCategoryIds: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                localSelectedCategory: this.selectedCategory,
                localSelectedDetonator: this.selectedDetonator,
                interactionModeEnum: InteractionMode,
                categoryDialog: false,
            };
        },
        computed: {
            detonators() {
                let filteredItems = [];
                if (this.magazineItems.length > 0 && this.localSelectedCategory) {
                    filteredItems = this.magazineItems.filter(
                        (x) => x.magazineItemCategoryId == this.localSelectedCategory.id
                    );
                }

                return filteredItems;
            },
        },
        methods: {
            setMode(mode) {
                if (this.selectedMode == mode) {
                    // Toggle
                    this.$emit("update:selectedMode", this.interactionModeEnum.Normal);
                    return;
                }
                this.$emit("update:selectedMode", mode);
            },
            onCategoryChange(category) {
                this.localSelectedCategory = category;
                this.$emit("update:selectedCategory", category);
                this.$emit("update:selectedDetonator", null);
                this.$emit("update:selectedMode", this.interactionModeEnum.Normal);
                this.categoryDialog = false;
            },
            onDetonatorClick(detonator) {
                this.localSelectedDetonator = {
                    Id: detonator.id,
                    Label: detonator.name,
                    Color: detonator.icon.backgroundColor,
                };
                this.$emit("update:selectedDetonator", this.localSelectedDetonator);
                this.$emit("update:selectedMode", this.interactionModeEnum.Normal);
            },
            openDialog() {
                // Deselect the detonator if selected because it was still adding to canvas behind the dialog
                this.$emit("update:selectedDetonator", null);
                this.categoryDialog = true;
            },
            isSelected(itemId) {
                return this.localSelectedDetonator?.Id === itemId;
            },
            isUsed(itemId) {
                return this.usedMagazineItemIds.some((id) => id === itemId);
            },
            isSelectedCategory(categoryId) {
                return this.localSelectedCategory?.id === categoryId;
            },
            isCategoryUsed(categoryId) {
                return this.usedMagazineItemCategoryIds.some((id) => id === categoryId);
            },
        },
        watch: {
            selectedDetonator(newVal) {
                this.localSelectedDetonator = newVal;
            },
            selectedCategory(newVal) {
                this.localSelectedCategory = newVal;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .detonator-btn {
        width: 80px !important;
        height: 80px !important;
        border-radius: 8px;
        background-color: white !important;
        border: 1px solid #e0e0e0 !important;

        &.selected {
            background-color: #e3f2fd !important;
            border-color: #2196f3 !important;
            border-width: 2px !important;
        }

        &.used {
            background-color: #f0f7ef !important;

            // Selected Badge
            &::after {
                content: "";
                position: absolute;
                top: 4px;
                right: 4px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #4caf50;
            }
        }

        &.used.selected {
            background-color: #e3f2fd !important;
            border-color: #2196f3 !important;

            &::after {
                background-color: #2196f3;
            }
        }
    }

    .detonator > .name {
        position: absolute;
        inset: 10px 0px 0px 0px;
        color: white;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5), -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5),
            -1px 1px 0 rgba(0, 0, 0, 0.5);
        font-size: 1.3em;
    }

    .detonator {
        position: relative;
    }

    .actions {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin: 0 auto;
        max-width: 640px;
        height: 48px;
        padding: 0px;
    }

    .detonators {
        margin: 0 auto;
        min-height: 100px;
        max-height: 100px;
        max-width: 640px;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .category-btn {
        height: 64px !important;
        width: 100% !important;
        padding: 8px 16px !important;
        background-color: white !important;
        border: 1px solid #e0e0e0 !important;
        position: relative;

        &:hover {
            background-color: #f5f5f5 !important;
            border-color: #bdbdbd !important;
        }

        &.selected {
            background-color: #e3f2fd !important;
            border-color: #2196f3 !important;
            border-width: 2px !important;
        }

        &.used {
            background-color: #f0f7ef !important;

            &::after {
                content: "";
                position: absolute;
                top: 8px;
                right: 8px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #4caf50;
            }
        }

        &.used.selected {
            background-color: #e3f2fd !important;
            border-color: #2196f3 !important;

            &::after {
                background-color: #2196f3;
            }
        }
    }

    .select-category-btn {
        width: fit-content;
        height: 64px !important;
    }

    .category-content {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
    }

    .category-label {
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
