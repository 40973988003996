<template>
    <div v-if="field && field.dependsOn && field.dependsOn.addOnly" class="d-flex justify-center">
        <schema-entity-add
            style="width: 100%"
            v-bind="$attrs"
            :value="value"
            :label="field.title"
            :rules="rules"
            :entity-key="field.dependsOn.type"
            :clearable="!required"
            search-when-blank
            v-on="$listeners"
        >
            <template #prepend="{ selectedItem }">
                <exm-icon v-if="selectedItem" style="height: 22px; width: 22px" :icon="selectedItem" />
            </template>
        </schema-entity-add>
    </div>
</template>

<script>
    import formFieldMixin from "./formFieldMixin";
    import SchemaEntityAdd from "@/features/schemas/components/SchemaEntityAdd.vue";

    import ExmIcon from "@/components/ExmIcon.vue";

    export default {
        types: ["addonlyentity"],
        mixins: [formFieldMixin],
        components: { SchemaEntityAdd, ExmIcon },
        props: {
            value: {
                type: String,
                default: null,
            },
        },
    };
</script>
