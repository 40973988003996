<template>
    <v-dialog v-model="localDialog" :max-width="maxWidth">
        <template v-if="value == undefined" v-slot:activator="{ on, attrs }">
            <slot name="activator" :on="on" :open="open">
                <v-btn dark v-bind="attrs" v-on="on">Click Me</v-btn>
            </slot>
        </template>

        <v-card>
            <v-card-title>{{ title }}</v-card-title>

            <v-card-text>
                <v-alert v-if="irreversible" outlined dense type="error" prominent>
                    <span class="message">{{ irreversibleMessage }}</span>
                </v-alert>

                <slot name="hint"></slot>

                <slot name="message">
                    <span class="message subtitle-1">{{ message }}</span>
                </slot>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!hideCancel" text @click="localDialog = false">Cancel</v-btn>
                <v-btn color="primary" text @click="confirm">{{ confirmLabel }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            value: {
                type: Boolean,
                default: undefined,
            },
            irreversible: {
                type: Boolean,
                default: () => false,
            },
            irreversibleMessage: {
                type: String,
                default: () => "This action cannot be undone.",
            },
            title: {
                type: String,
                default: () => "Confirm",
            },
            message: {
                type: String,
                default: () => "Are you sure you want to remove this item?",
            },
            maxWidth: {
                type: String,
                default: () => "500px",
            },
            confirmLabel: {
                type: String,
                default: () => "Confirm",
            },
            hideCancel: {
                type: Boolean,
                default: () => false,
            },
        },
        data() {
            return {
                localDialog: this.value,
            };
        },
        watch: {
            value(newVal) {
                this.localDialog = newVal;
            },
            localDialog(newVal) {
                this.$emit("input", newVal);
            },
        },
        methods: {
            open() {
                this.localDialog = true;
            },
            confirm() {
                this.localDialog = false;
                this.$emit("confirm");
            },
        },
    };
</script>

<style scoped>
    .message {
        white-space: break-spaces;
    }
</style>
