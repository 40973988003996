<template>
    <schema-form ref="form" v-bind="$attrs" v-on="$listeners" :on-before-save="onBeforeSave">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>

        <template #field-magazineCapacityItems="{ item }">
            <div class="mb-6">
                <div class="d-flex align-center">
                    <div class="text-h6 flex-grow-1">Magazine Capacities</div>
                    <app-button color="secondary" @click="addCapacity(item)" :disabled="isAddCapacityDisabled(item)">
                        <v-icon left>mdi-plus</v-icon>
                        Add Capacity
                    </app-button>
                </div>
                <div class="capacity-list">
                    <div v-for="(capacity, index) in item.magazineCapacityItems" :key="index" class="capacity-item">
                        <div class="magazine-type d-flex align-center">
                            <form-enum
                                v-model="capacity.magazineType"
                                :field="magazineTypeField"
                                validate-on-blur
                                :customRules="buildRules(magazineTypeField)"
                                required
                                :disabled="!!capacity.id"
                            />
                            <app-button
                                class="ml-2"
                                style="height: 38px"
                                color="secondary"
                                small
                                @click="deleteCapacity(item, index)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </app-button>
                        </div>
                        <div class="quantity-unit">
                            <form-number
                                v-model="capacity.quantity"
                                :field="quantityField"
                                validate-on-blur
                                :customRules="buildRules(quantityField)"
                                required
                            />
                            <form-search-select
                                v-model="capacity.unitId"
                                :field="unitField"
                                validate-on-blur
                                :customRules="buildRules(unitField)"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </schema-form>
</template>

<script>
    import FormEnum from "@/features/schemas/formFields/FormEnum.vue";
    import FormNumber from "@/features/schemas/formFields/FormNumber.vue";
    import FormSearchSelect from "@/features/schemas/formFields/FormSearchSelect.vue";
    import { getEnum } from "@/features/schemas/services/schemaProvider";
    import { list } from "@/features/schemas/services/schemaApi";
    import { buildRules } from "@/services/ruleBuilder";

    export default {
        props: {
            parentEntity: {
                type: Object,
                default: null,
            },
        },
        components: { FormEnum, FormNumber, FormSearchSelect },

        data() {
            return {
                unitField: {
                    dependsOn: {
                        type: "unit",
                    },
                    required: {},
                    type: "guid?",
                    title: "Unit",
                },
                magazineTypeField: { title: "Magazine Type", type: "MagazineType", required: {} },
                quantityField: { title: "Quantity", type: "number", required: {} },
                magazineTypeEnums: getEnum("magazineType"),
                units: [],
                globalCapacities: [],
            };
        },

        async mounted() {
            const unitQuery = await list("unit", {
                recordState: "active",
            });
            this.units = unitQuery.items;

            const capacityQuery = await list("magazineCapacity");
            this.globalCapacities = capacityQuery.items;
        },

        methods: {
            buildRules,
            async onBeforeSave(item) {
                var localCapacities = item?.magazineCapacityItems ?? null;

                if (!localCapacities) {
                    return true;
                }

                // Get the global capacities
                const globalCapacities = this.globalCapacities;

                // Create a map of global capacities with MagazineType as key and UnitId as value
                const globalCapacityMap = new Map();
                globalCapacities.forEach((globalItem) => {
                    globalCapacityMap.set(globalItem.magazineType, globalItem.unitId);
                });

                // Create a map of local capacities with MagazineType as key and UnitId as value
                const localCapacityMap = new Map();
                localCapacities.forEach((localItem) => {
                    localCapacityMap.set(localItem.magazineType, localItem.unitId);
                });

                // Determine if we are changing any magazineCapacity mapping
                const hasChanges = Array.from(localCapacityMap.entries()).some(([magazineType, unitId]) => {
                    const globalUnitId = globalCapacityMap.get(magazineType);
                    return globalUnitId !== undefined && globalUnitId !== unitId;
                });

                // Prompt the user if changes are detected
                if (hasChanges) {
                    const shouldProceed = confirm(
                        "You are changing a magazine capacity MagazineType to Unit binding. This will change all MagazineType to Unit bindings accross all Magazines. Do you want to proceed?"
                    );
                    return shouldProceed;
                }

                return true;
            },

            addCapacity(item) {
                if (!item.magazineCapacityItems || item.magazineCapacityItems.length == 0) {
                    this.$set(item, "magazineCapacityItems", []);
                }

                const usedMagazineTypes = item.magazineCapacityItems.map((x) => x.magazineType);
                const magTypeKeys = Object.keys(this.magazineTypeEnums);
                const avalableKeys = magTypeKeys.filter((x) => !usedMagazineTypes.includes(x));

                if (avalableKeys.length == 0) {
                    return;
                }

                const newCapacity = {
                    id: undefined,
                    magazineAreaId: item.id,
                    magazineType: avalableKeys[0],
                    quantity: 0,
                    unitId: this.units[0].id,
                };

                item.magazineCapacityItems.push(newCapacity);
            },
            deleteCapacity(item, index) {
                item.magazineCapacityItems.splice(index, 1);
            },
            isAddCapacityDisabled(item) {
                if (!item.magazineCapacityItems || item.magazineCapacityItems.length == 0) {
                    return false;
                }
                const usedMagazineTypes = item.magazineCapacityItems.map((x) => x.magazineType);
                return Object.keys(this.magazineTypeEnums).length === usedMagazineTypes.length;
            },
        },
    };
</script>

<style scoped>
    .capacity-list {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        gap: 10px;
    }
    .capacity-item {
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 10px 10px 0px 10px;
        background-color: #f9f9f9;
    }

    .quantity-unit {
        display: flex;
        gap: 10px;
    }

    .magazine-type .v-btn {
        align-self: start;
    }
</style>
