<template>
    <app-select
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required"
        :items="enumValues"
        :multiple="field.isMultiple"
        v-on="$listeners"
    >
        <template #item="{ item, attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                    <v-list-item-title>
                        <div class="d-flex align-center">
                            <exm-icon
                                v-if="item.value"
                                style="height: 22px; width: 22px"
                                :icon="{ iconImage: item.value }"
                                class="mr-2"
                            />
                            <span>{{ item.text }}</span>
                        </div>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </app-select>
</template>

<script>
    import { mapGetters } from "vuex";

    import ExmIcon from "@/components/ExmIcon.vue";
    import formFieldMixin from "./formFieldMixin";
    import { enumAsSelectListWithKeyValue } from "@/services/enumService";

    export default {
        types: ["enum"],
        components: { ExmIcon },
        mixins: [formFieldMixin],
        computed: {
            ...mapGetters({
                getEnum: "schema/getEnum",
            }),
            enumValues() {
                const value = this.getEnum(this.field.type);
                return enumAsSelectListWithKeyValue(value);
            },
        },
    };
</script>
