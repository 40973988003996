<template>
    <app-select
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required"
        :items="enumValues"
        :multiple="field.isMultiple"
        v-on="$listeners"
    />
</template>

<script>
    import formFieldMixin from "./formFieldMixin";
    import { mapGetters } from "vuex";
    import { enumAsSelectListWithKeyValue } from "@/services/enumService";

    export default {
        types: ["enum"],
        mixins: [formFieldMixin],
        computed: {
            ...mapGetters({
                getEnum: "schema/getEnum",
            }),
            enumValues() {
                const value = this.getEnum(this.field.type);
                return enumAsSelectListWithKeyValue(value);
            },
        },
    };
</script>
