<template>
    <v-text-field
        v-bind="$attrs"
        v-on="$listeners"
        class="dense-text-field"
        :style="{ height: height, fontSize: fontSize }"
        hide-details
        dense
        outlined
    />
</template>

<script>
    export default {
        props: {
            height: {
                type: String,
                default: "24px", // Default to a compact height
            },
            fontSize: {
                type: String,
                default: "12px", // Default font size
            },
        },
    };
</script>

<style scoped>
    .dense-text-field {
        padding: 0;
        margin: 0;
        height: inherit;
        min-height: 0 !important;
        display: flex;
        align-items: center;
    }

    /* Use deep selectors to target Vuetify's internal classes */
    ::v-deep .v-input__control {
        min-height: 0 !important;
        height: 100% !important;
        display: flex;
        align-items: center;
    }

    ::v-deep .v-input__slot {
        min-height: 0 !important;
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
        display: flex;
        align-items: center;
    }

    ::v-deep .v-text-field__slot {
        min-height: 0 !important;
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
        display: flex;
        align-items: center;
    }

    ::v-deep input {
        min-height: 0 !important;
        height: 100% !important;
        font-size: inherit !important;
        padding: 0 4px !important; /* Adjust padding as needed */
        margin: 0 !important;
        box-sizing: border-box;
    }

    /* Additional styles to ensure no extra space */
    ::v-deep .v-input {
        height: 100% !important;
        min-height: 0 !important;
    }

    ::v-deep .v-input__append-inner,
    ::v-deep .v-input__prepend-inner {
        height: 100% !important;
        min-height: 0 !important;
        display: flex;
        align-items: center;
    }
</style>
