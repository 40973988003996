export const capacityMixin = {
    data() {
        return {
            exceededCapacityDialog: false,
            exceededCapacities: [],
        };
    },
    methods: {
        async validate() {
            const childExceededCapacities = this.$refs.itemOrderTable.exceededCapacities;
            if (childExceededCapacities.length > 0) {
                this.exceededCapacities = childExceededCapacities;
                this.exceededCapacityDialog = true;
            } else {
                this.exceededCapacities = [];
                await this.save();
            }
        },
    },
};
