<template>
    <div class="custom-scrollbar-wrapper" :class="{ 'is-visible': isVisible }">
        <div class="custom-scrollbar-track">
            <div
                ref="scrollbarThumb"
                class="custom-scrollbar-thumb"
                :style="thumbStyle"
                @mousedown="startDragging"
            ></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CustomScrollbar",
        props: {
            targetElement: {
                type: HTMLElement,
                required: true,
            },
            offset: {
                type: Object,
                default: () => ({
                    left: 0,
                    right: 0,
                }),
            },
        },
        data() {
            return {
                isDragging: false,
                startX: 0,
                startScrollLeft: 0,
                scrollPosition: 0,
                resizeObserver: null,
                isScrollable: false,
                contentWidth: 0,
                containerWidth: 0,
            };
        },
        computed: {
            thumbStyle() {
                const thumbWidthPercent = Math.max((this.containerWidth / this.contentWidth) * 100, 10);

                const availableWidth = this.containerWidth - this.offset.left - this.offset.right;
                const maxScrollPosition = this.contentWidth - this.containerWidth;
                const scrollPercentage = maxScrollPosition ? this.scrollPosition / maxScrollPosition : 0;
                const thumbPosition = scrollPercentage * (availableWidth - (availableWidth * thumbWidthPercent) / 100);

                return {
                    width: `${thumbWidthPercent}%`,
                    transform: `translateX(${thumbPosition}px)`,
                };
            },
            isVisible() {
                return this.isScrollable;
            },
        },
        mounted() {
            window.addEventListener("mousemove", this.onDrag);
            window.addEventListener("mouseup", this.stopDragging);
            this.targetElement.addEventListener("scroll", this.handleScroll);

            this.resizeObserver = new ResizeObserver(this.handleResize);
            this.resizeObserver.observe(this.targetElement);

            this.updateDimensions();
        },
        beforeDestroy() {
            window.removeEventListener("mousemove", this.onDrag);
            window.removeEventListener("mouseup", this.stopDragging);
            this.targetElement.removeEventListener("scroll", this.handleScroll);

            if (this.resizeObserver) {
                this.resizeObserver.disconnect();
            }
        },
        methods: {
            updateDimensions() {
                this.contentWidth = this.targetElement.scrollWidth;
                this.containerWidth = this.targetElement.clientWidth;
                this.isScrollable = this.contentWidth > this.containerWidth;

                // Reset scroll position if no longer scrollable
                if (!this.isScrollable) {
                    this.scrollPosition = 0;
                    this.targetElement.scrollLeft = 0;
                }
            },
            handleResize() {
                this.updateDimensions();

                // Ensure scroll position doesn't exceed new boundaries
                const maxScroll = this.contentWidth - this.containerWidth;
                if (this.scrollPosition > maxScroll) {
                    this.targetElement.scrollLeft = maxScroll;
                    this.scrollPosition = maxScroll;
                }
            },
            handleScroll() {
                this.scrollPosition = this.targetElement.scrollLeft;
                this.updateDimensions();
            },
            startDragging(e) {
                if (!this.isScrollable) return;
                this.isDragging = true;
                this.startX = e.clientX;
                this.startScrollLeft = this.targetElement.scrollLeft;
                document.body.style.userSelect = "none";
            },
            stopDragging() {
                this.isDragging = false;
                document.body.style.userSelect = "";
            },
            onDrag(e) {
                if (!this.isDragging || !this.isScrollable) return;

                e.preventDefault();
                const deltaX = e.clientX - this.startX;
                const scrollRatio = this.contentWidth / this.containerWidth;

                this.targetElement.scrollLeft = this.startScrollLeft + deltaX * scrollRatio;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .custom-scrollbar-wrapper {
        position: absolute;
        bottom: 37px;
        left: 0;
        right: 0;
        height: 12px;
        opacity: 0;
        transition: opacity 0.2s;
        z-index: 100;
        pointer-events: none;

        &.is-visible {
            opacity: 1;
            pointer-events: auto;
        }
    }

    .custom-scrollbar-track {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
    }

    .custom-scrollbar-thumb {
        position: absolute;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
</style>
