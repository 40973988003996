<template>
    <v-card outlined>
        <v-expansion-panels v-model="panel" flat>
            <v-expansion-panel>
                <v-expansion-panel-header class="text-h6">
                    Projected Capacities ({{ formatDateOnly(projectedDate) }})
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-if="loading" class="text-center py-4">
                        <v-progress-circular indeterminate color="primary" />
                    </div>
                    <div v-show="!loading" class="magazine-capacity-chart">
                        <div class="chart-container">
                            <canvas ref="chartRef" />
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
    import axios from "axios";
    import { formatDateOnly, addDays } from "@/services/dateUtility";
    import Chart from "chart.js/auto";
    import { camelToPascalCase } from "@/services/stringUtility";
    import { getEnum } from "@/features/schemas/services/schemaProvider";
    import { reverseEnum } from "@/services/enumService";

    export default {
        props: {
            value: {
                type: Object,
                required: true,
            },
            batch: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        emits: ["input", "exceededCapacitiesChanged"],
        data() {
            return {
                chart: null,
                panel: 0,
                totalCapacities: {},
                magazineTypes: reverseEnum(getEnum("MagazineType")),
            };
        },
        async mounted() {
            this.totalCapacities = await this.getMagazineTypeCapacities();
            this.createOrUpdateChart();
        },
        computed: {
            projectedDate() {
                return addDays(this.batch.dateStarted, this.batch.deliveryLeadTimeDays);
            },
            chartData() {
                return Object.keys(this.totalCapacities).map((magazineType) => {
                    const capacity = this.totalCapacities[magazineType];
                    return {
                        type: camelToPascalCase(this.magazineTypes[magazineType]),
                        currentCapacity: this.value[magazineType] || 0,
                        totalCapacity: capacity.totalCapacity,
                        unit: capacity.unit,
                    };
                });
            },
            exceededCapacities() {
                return this.chartData
                    .filter((item) => item.currentCapacity > item.totalCapacity)
                    .map((item) => ({
                        type: item.type,
                        currentCapacity: item.currentCapacity,
                        totalCapacity: item.totalCapacity,
                        exceedAmount: item.currentCapacity - item.totalCapacity,
                        unit: item.unit,
                    }));
            },
        },
        watch: {
            value: {
                handler() {
                    this.createOrUpdateChart();
                },
                deep: true,
            },
            exceededCapacities: {
                handler(newValue) {
                    this.$emit("exceededCapacitiesChanged", newValue);
                },
                deep: true,
            },
        },
        methods: {
            formatDateOnly,
            async getMagazineTypeCapacities() {
                const { data } = await axios.get(`/api/MagazineOrder/GetMagazineTypeCapacities`);
                return data;
            },

            createOrUpdateChart() {
                if (this.chart) {
                    this.chart.data = this.getChartData();
                    this.chart.update();
                } else {
                    this.createChart();
                }
            },

            createChart() {
                const ctx = this.$refs.chartRef.getContext("2d");

                this.chart = new Chart(ctx, {
                    type: "bar",
                    data: this.getChartData(),
                    options: {
                        indexAxis: "y",
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                right: 200,
                            },
                        },
                        scales: {
                            x: {
                                beginAtZero: true,
                                max: 100,
                                title: {
                                    display: true,
                                    text: "Capacity Usage (%)",
                                },
                            },
                            y: {
                                grid: {
                                    display: false,
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    label: (context) => {
                                        const data = this.chartData[context.dataIndex];
                                        return `${data.currentCapacity.toFixed(2)} / ${data.totalCapacity.toFixed(
                                            2
                                        )}  (${data.unit}) (${context.parsed.x.toFixed(1)}%)`;
                                    },
                                },
                            },
                        },
                    },
                    plugins: [
                        {
                            id: "externalLabels",
                            afterDatasetsDraw: (chart) => {
                                const {
                                    ctx,
                                    chartArea,
                                    scales: { y },
                                } = chart;
                                chart.data.datasets[0].data.forEach((value, index) => {
                                    const data = this.chartData[index];
                                    const text = `${data.currentCapacity.toFixed(2)} / ${data.totalCapacity.toFixed(
                                        2
                                    )} (${data.unit})`;
                                    const xValue = chartArea.right + 10;
                                    const yValue = y.getPixelForValue(index);

                                    ctx.save();
                                    ctx.font = "12px Arial";
                                    ctx.fillStyle = data.currentCapacity > data.totalCapacity ? "#e74c3c" : "black";
                                    ctx.textAlign = "left";
                                    ctx.textBaseline = "middle";
                                    ctx.fillText(text, xValue, yValue);
                                    ctx.restore();
                                });
                            },
                        },
                    ],
                });
            },

            getChartData() {
                return {
                    labels: this.chartData.map((item) => item.type),
                    datasets: [
                        {
                            data: this.chartData.map((item) => (item.currentCapacity / item.totalCapacity) * 100),
                            backgroundColor: this.chartData.map((item) =>
                                item.currentCapacity > item.totalCapacity ? "#e74c3c" : "#2c3e50"
                            ),
                            barPercentage: 0.8,
                        },
                    ],
                };
            },
        },
    };
</script>

<style scoped>
    .magazine-capacity-chart {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .chart-container {
        flex-grow: 1;
        min-height: 200px;
    }

    .capacities-text {
        font-weight: medium;
        font-size: 1.25em;
        text-align: left;
    }
</style>
