<template>
    <v-data-table
        v-if="standardItems"
        :headers="standardItemHeaders"
        :items="customStandardItems"
        disable-pagination
        hide-default-footer
        fixed-header
        class="detonator-table"
    >
        <template #item.name="{ item }">
            <exm-icon v-if="item.magazineItem.icon" :icon="item.magazineItem.icon" width="30px" height="30px" />
        </template>

        <template #item.magazineItemId="{ item }">
            <div>{{ item.name }}</div>
        </template>

        <template #item.spare="{ item }">
            <form-number
                class="centered-form-number"
                v-model="item.spare"
                :field="{ title: `Spare` }"
                :customRules="[(v) => v === null || v === '' || Number(v) > 0 || 'Value must be positive']"
                hide-details
                clearable
                @blur="updateStandardItem(item)"
                validate-on-blur
            />
        </template>

        <template v-slot:foot>
            <tfoot>
                <tr>
                    <td v-for="(header, i) in standardItemHeaders" :key="i">
                        <div v-if="header.value == 'name'">Total</div>
                        <div v-if="header.value == 'quantity'">
                            {{ totalQuantity }}
                        </div>
                        <div v-if="header.value == 'spare'">
                            {{ totalSpare }}
                        </div>
                        <div v-else></div>
                    </td>
                </tr>
            </tfoot>
        </template>
    </v-data-table>
</template>

<script>
    import ExmIcon from "@/components/ExmIcon";
    import FormNumber from "@/features/schemas/formFields/FormNumber.vue";

    export default {
        name: "DetonatorControl",
        components: { ExmIcon, FormNumber },
        props: {
            standardItems: {
                type: Array,
                required: true,
            },
            magazineItems: {
                type: Array,
                required: true,
            },
            magazineItemCategories: {
                type: Array,
                required: true,
            },
        },
        emits: ["updateSpare"],
        computed: {
            standardItemHeaders() {
                return [
                    { text: "Icon", value: "name" },
                    { text: "Name", value: "magazineItemId" },
                    { text: "Quantity", value: "quantity" },
                    { text: "Spare", value: "spare" },
                ];
            },
            customStandardItems() {
                var standardItemsWithExtras = this.standardItems.map((item) => {
                    const magazineItem = this.magazineItems.find((mi) => mi.id === item.magazineItemId);
                    const magazineItemCategory = this.magazineItemCategories.find(
                        (x) => x.id === magazineItem.magazineItemCategoryId
                    );
                    return {
                        ...item,
                        name: `${magazineItemCategory?.name} - ${magazineItem?.name}`,
                        magazineItem: magazineItem,
                        magazineItemCategory: magazineItemCategory,
                    };
                });

                standardItemsWithExtras = standardItemsWithExtras.filter((x) => {
                    if (x.coordinates) return x;
                });

                if (standardItemsWithExtras.length == 0) {
                    return [];
                }

                return standardItemsWithExtras.sort((a, b) => {
                    const aCategoryPriority = a.magazineItemCategory ? a.magazineItemCategory.priority : -Infinity;
                    const bCategoryPriority = b.magazineItemCategory ? b.magazineItemCategory.priority : -Infinity;

                    if (aCategoryPriority !== bCategoryPriority) {
                        return bCategoryPriority - aCategoryPriority;
                    }

                    const aItemPriority = a.magazineItem ? a.magazineItem.priority : -Infinity;
                    const bItemPriority = b.magazineItem ? b.magazineItem.priority : -Infinity;

                    return bItemPriority - aItemPriority;
                });
            },
            totalQuantity() {
                if (this.customStandardItems.length == 0) return 0;
                return this.customStandardItems.reduce((total, item) => total + (item.quantity || 0), 0);
            },
            totalSpare() {
                if (this.customStandardItems.length == 0) return 0;
                return this.customStandardItems.reduce((total, item) => total + (item.spare || 0), 0);
            },
        },

        methods: {
            updateStandardItem(item) {
                if (item.spare < 0) return;

                if (item.spare === 0) {
                    item.spare = undefined;
                }
                this.$emit("updateSpare", item);
            },
        },
    };
</script>

<style>
    .detonator-table {
        height: 77.5vh !important;
    }

    .detonator-table .v-data-table__wrapper {
        height: 100%;
        overflow-y: auto;
        position: relative;
    }

    .detonator-table table {
        width: 100%;
        border-collapse: collapse;
    }

    .detonator-table tbody {
        width: 100%;
    }

    .detonator-table tr {
        width: 100%;
        display: table-row;
    }

    .detonator-table td {
        display: table-cell;
    }

    .detonator-table tfoot {
        position: sticky;
        bottom: 0;
        z-index: 2;
        background: white;
        box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
        width: 100%;
    }

    .detonator-table tfoot td {
        background: white;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding: 8px 16px !important;
    }

    tfoot td > div {
        font-size: 1.25em;
    }

    .centered-form-number {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 6px;
        padding-bottom: 6px;
    }
</style>
