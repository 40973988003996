<template>
    <schema-form ref="form" v-bind="$attrs" v-on="$listeners">
        <template #field-iconId="{ field, item, autofocus }">
            <icon-add-only-field
                v-model="item.iconId"
                :key="field.value"
                :field="iconField(field)"
                :autofocus="autofocus"
                :fixed-values="fixedValues"
                validate-on-blur
            />
        </template>
        <template #field-min="{ field, item, autofocus }">
            <form-number
                v-model="item.min"
                :key="field.value"
                :field="field"
                :custom-rules="customRules(item, field)"
                :autofocus="autofocus"
                validate-on-blur
            />
        </template>
        <template #field-max="{ field, item, autofocus }">
            <form-number
                v-model="item.max"
                :key="field.value"
                :field="field"
                :custom-rules="customRules(item, field)"
                :autofocus="autofocus"
                validate-on-blur
            />
        </template>
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </schema-form>
</template>

<script>
    import FormNumber from "../formFields/FormNumber.vue";
    import IconAddOnlyField from "../formFields/IconAddOnlyField.vue";

    export default {
        props: {
            parentEntity: {
                type: Object,
                default: null,
            },
        },

        components: { IconAddOnlyField, FormNumber },

        computed: {
            fixedValues() {
                if (this.parentEntity == null || this.parentEntity.key !== "magazineItemCategory") {
                    return null;
                }

                return {
                    iconImage: "detClip",
                };
            },
        },

        methods: {
            customRules(item, field) {
                const { min, max } = item;

                const rules = [];

                rules.push((value) => {
                    if (!value && !field.required) return true;
                    return value >= 0 || "Value must be greater than or equal to 0";
                });

                if (field.key == "min") {
                    rules.push((value) => {
                        if (!value && !field.required) return true;
                        return value < max || "Min must be less than or equal to Max";
                    });
                }

                if (field.key == "max") {
                    rules.push((value) => {
                        if (!value && !field.required) return true;
                        return value > min || "Max must be greater than Min";
                    });
                }

                return rules;
            },
            iconField(field) {
                if (this.parentEntity == null || this.parentEntity.key !== "magazineItemCategory") {
                    // Icon field should be required in all other instances.
                    field.required = {};
                    return field;
                }

                // IconId is nullable to support isShowIcon being false, so we need to add some client side validation back in.
                if (this.parentEntity.item.isShowIcon === true) {
                    field.required = {};
                }

                return field;
            },
            async save() {
                return await this.$refs.form.save();
            },
        },
    };
</script>
