var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"actions"},[_c('v-col',{staticClass:"col-auto pa-1"},[_c('app-button',{attrs:{"large":"","color":_vm.selectedMode === _vm.interactionModeEnum.Edit ? 'blue' : 'black',"outlined":""},on:{"click":function($event){return _vm.setMode(_vm.interactionModeEnum.Edit)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-col',{staticClass:"col-auto pa-1"},[_c('app-button',{attrs:{"large":"","color":_vm.selectedMode === _vm.interactionModeEnum.Delete ? 'red' : 'black',"outlined":""},on:{"click":function($event){return _vm.setMode(_vm.interactionModeEnum.Delete)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-col',{staticClass:"col-auto pa-1"},[_c('app-button',{attrs:{"large":"","color":_vm.selectedMode === _vm.interactionModeEnum.Region ? 'primary' : 'black',"outlined":""},on:{"click":function($event){return _vm.setMode(_vm.interactionModeEnum.Region)}}},[_c('v-icon',[_vm._v("mdi-select")])],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"col-auto pa-1"},[_c('app-button',{attrs:{"color":"primary","outlined":"","large":""},on:{"click":function($event){return _vm.openDialog()}}},[_vm._v("Select Category")])],1)],1),_c('v-row',{staticClass:"detonators px-0 my-2 flex-nowrap",attrs:{"cols":"auto","no-gutters":""}},[_vm._l((_vm.detonators),function(item){return _c('v-col',{key:item.id,staticClass:"px-1 py-0 my-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"detonator-btn",class:{
                    selected: _vm.isSelected(item.id),
                    used: _vm.isUsed(item.id),
                },attrs:{"text":""},on:{"click":function($event){return _vm.onDetonatorClick(item)}}},[_c('div',{staticClass:"detonator"},[(item.icon)?_c('exm-icon',{attrs:{"icon":item.icon,"width":"72px","height":"72px"}}):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])],1)])],1)}),(_vm.detonators.length === 0)?_c('div',{staticClass:"v-center py-6",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',[_vm._v("mdi-alert-circle")]),_c('div',[_vm._v("Please select a category to add detonators to the grid")])],1)]):_vm._e()],2),_c('app-dialog',{attrs:{"value":_vm.categoryDialog,"persistent":true,"overlay":true}},[_c('v-card',[_c('v-card-title',[_vm._v("Select Detonator Category")]),_c('v-card-text',{staticClass:"pt-2"},[(_vm.detonatorCategories.length > 0)?_c('div'):_vm._e(),_vm._l((_vm.detonatorCategories),function(cat){return _c('v-row',{key:cat.id,staticClass:"d-flex align-center pa-2"},[_c('v-btn',{staticClass:"category-btn",class:{
                            selected: _vm.isSelectedCategory(cat.id),
                            used: _vm.isCategoryUsed(cat.id),
                        },attrs:{"rounded":"","elevation":"2"},on:{"click":function($event){return _vm.onCategoryChange(cat)}}},[_c('div',{staticClass:"category-content"},[(cat.icon)?_c('exm-icon',{attrs:{"icon":cat.icon,"width":"32px","height":"32px"}}):_vm._e(),_c('div',{staticClass:"category-label"},[_vm._v(_vm._s(cat.name))])],1)])],1)})],2),_c('v-card-actions',[_c('app-button',{attrs:{"text":""},on:{"click":function($event){_vm.categoryDialog = false}}},[_vm._v("Cancel")]),_c('v-spacer')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }