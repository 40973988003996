<template>
    <div>
        <app-dialog :value="value" persistent max-width="90vw" style="min-height: 800px">
            <v-card v-if="value">
                <v-card-title>
                    <v-stepper v-model="step" style="width: 100%">
                        <v-stepper-header>
                            <v-stepper-step :complete="step > 1" step="1">Start New Order</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 2" step="2">Confirm Items</v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
                </v-card-title>

                <v-card-text style="width: 100%">
                    <v-stepper class="mt-1" v-model="step">
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-form ref="form" @submit="createOrderBatch" @submit.prevent>
                                    <v-row>
                                        <v-col cols="3" class="mt-2">
                                            <form-date-time-field
                                                v-model="newBatch.dateStarted"
                                                :field="{ title: `Date Order Created` }"
                                                disabled
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3">
                                            <app-text-field
                                                v-model="newBatch.orderId"
                                                label="Order Id"
                                                :rules="[(v) => !!v || 'is required']"
                                            />
                                        </v-col>
                                        <v-col cols="2">
                                            <v-btn icon outlined small fab class="ml-2" @click="generateOrderId()">
                                                <v-icon>mdi-sync</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5">
                                            <app-text-area
                                                v-model="newBatch.comments"
                                                label="Comments"
                                                :rules="[
                                                    (v) => !v || v.length <= 1000 || 'Max length is 1000 characters',
                                                ]"
                                                validate-on-blur
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <app-text-field
                                                        type="number"
                                                        v-model="newBatch.deliveryLeadTimeDays"
                                                        label="Delivery Lead time days"
                                                        step="1"
                                                        :disabled="isBackPressed"
                                                        :rules="[
                                                            (v) => (!!v && Number(v) > 0) || 'Value must be positive',
                                                            (v) =>
                                                                (!!v && Number.isInteger(Number(v))) ||
                                                                'Value must be an integer',
                                                        ]"
                                                        validate-on-blur
                                                        v-on="on"
                                                        v-bind="attrs"
                                                        @input="onUpdateDeliveryLeadTimeDays"
                                                    />
                                                </template>
                                                <span>Days until delivery arrives</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            Select a period to look back from now to calculate stock usage
                                        </v-col>
                                        <v-col cols="3">
                                            <v-select
                                                v-model="selectedLookbackPeriod"
                                                :items="lookBackPeriods"
                                                @input="onUpdateLookback"
                                                return-object
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-show="isCustomLookback">
                                        <v-col cols="3">
                                            <form-date-time-field
                                                v-model="newBatch.usageLookBackPeriodStart"
                                                :field="{ title: `Usage Look Back Period Start` }"
                                                :disabled="isBackPressed"
                                                :rules="[() => !!v || 'is required']"
                                                required
                                            />
                                        </v-col>
                                        <v-col cols="3">
                                            <form-date-time-field
                                                v-model="newBatch.usageLookBackPeriodEnd"
                                                :field="{ title: `Usage Look Back Period End` }"
                                                :disabled="isBackPressed"
                                                :rules="[
                                                    (v) =>
                                                        (!!v && v > newBatch.usageLookBackPeriodStart) ||
                                                        'End date must be later than start date',
                                                    () => !!v || 'is required',
                                                ]"
                                                required
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col col-12 class="d-flex justify-end">
                                            <app-button
                                                class="mr-2"
                                                color="primary"
                                                :disabled="isSaving || isLoading || isEditMode"
                                                text
                                                @click="cancel()"
                                            >
                                                Cancel
                                            </app-button>
                                            <app-button
                                                color="primary"
                                                @click="createOrderBatch()"
                                                :loading="isSaving"
                                                :disable="isSaving || isLoading"
                                            >
                                                <span v-if="!isBackPressed">Generate Order</span>
                                                <span v-else>Forward</span>
                                            </app-button>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <item-order-table-custom
                                    ref="itemOrderTable"
                                    :itemOrderBatch="newBatch"
                                    :items="itemOrders"
                                    :editMode="isEditMode"
                                    :wizardMode="true"
                                    @completeOrder="completeOrder"
                                />
                                <v-row>
                                    <v-col class="d-flex justify-end">
                                        <app-button
                                            class="mr-3"
                                            color="primary"
                                            :disabled="isSaving || isLoading || isEditMode"
                                            text
                                            @click="cancel()"
                                        >
                                            Cancel
                                        </app-button>

                                        <app-button
                                            class="mr-2"
                                            color="primary"
                                            text
                                            @click="back()"
                                            :disabled="isSaving || isLoading"
                                        >
                                            Back
                                        </app-button>
                                        <app-button
                                            class="mr-2"
                                            color="primary"
                                            @click="onClose()"
                                            :disabled="isSaving"
                                        >
                                            <span>Close</span>
                                        </app-button>
                                        <app-button
                                            class="mr-2"
                                            color="primary"
                                            @click="validate()"
                                            :loading="isSaving"
                                            :disabled="isSaving || isLoading"
                                        >
                                            <span>Save</span>
                                            <v-icon>mdi-check</v-icon>
                                        </app-button>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </app-dialog>

        <capacity-exceeded-dialog
            v-model="exceededCapacityDialog"
            :exceededCapacities="exceededCapacities"
            @confirm="save"
        />

        <!-- Toast notification -->
        <v-snackbar v-model="snackbar" color="success" multi-line :timeout="3000" top centered elevation="24">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from "axios";
    import { DateTime } from "luxon";

    import { nowIso, now } from "@/services/dateUtility";

    import { capacityMixin } from "./capacityMixin";

    import CapacityExceededDialog from "./CapacityExceededDialog.vue";
    import ItemOrderTableCustom from "@/features/exmOrdering/ItemOrderTableCustom.vue";
    import FormDateTimeField from "@/features/schemas/formFields/FormDateTimeField.vue";

    export default {
        mixins: [capacityMixin],
        components: { CapacityExceededDialog, ItemOrderTableCustom, FormDateTimeField },
        props: {
            value: Boolean,
        },
        data() {
            return {
                wizard: false,
                isSaving: false,
                isLoading: false,
                newBatch: null,
                itemOrders: [],
                step: 1,
                isBackPressed: false,
                snackbar: false,
                snackbarText: "",
                lookBackPeriods: [
                    { text: "4 days", value: 4 },
                    { text: "1 week", value: 7 },
                    { text: "2 weeks", value: 14 },
                    { text: "1 month", value: 30 },
                    { text: "Custom", value: "custom" },
                ],
                selectedLookbackPeriod: null,
                isCustomLookback: false,
                isEditMode: false,
            };
        },
        watch: {
            value(newValue) {
                if (newValue) {
                    this.remount();
                }
            },
        },
        methods: {
            async generateOrderId() {
                this.$set(this.newBatch, "orderId", Math.floor(Math.random() * 1000000).toString());
            },
            remount() {
                this.isLoading = true;
                try {
                    this.newBatch = {
                        comments: "",
                        dateOrdered: null,
                        dateReceived: null,
                        dateStarted: null,
                        deliveryLeadTimeDays: 2,
                        orderId: null,
                        userId: "",
                    };
                    let userId = this.$store.state.authentication.user.id;
                    this.$set(this.newBatch, "userId", userId);
                    this.$set(this.newBatch, "dateStarted", nowIso());
                    this.$set(this.newBatch, "usageLookBackPeriodStart", now().minus({ days: 7 }).toISO());
                    this.$set(this.newBatch, "usageLookBackPeriodEnd", nowIso());

                    this.itemOrders = [];
                    this.step = 1;

                    const lastDeliveryLeadTimeDays = localStorage.getItem("lastDeliveryLeadTimeDays");
                    if (lastDeliveryLeadTimeDays) {
                        this.newBatch.deliveryLeadTimeDays = JSON.parse(lastDeliveryLeadTimeDays);
                    }

                    this.selectedLookbackPeriod = this.lookBackPeriods[1];
                    this.isCustomLookback = false;

                    const lastSelectedLookbackPeriod = localStorage.getItem("selectedLookbackPeriod");
                    if (lastSelectedLookbackPeriod) {
                        this.selectedLookbackPeriod = JSON.parse(lastSelectedLookbackPeriod).value;
                        if (this.selectedLookbackPeriod == "custom") {
                            this.isCustomLookback = true;
                        }
                    }
                    this.isEditMode = false;
                } finally {
                    this.isLoading = false;
                }
            },

            async deleteOrderBatch() {
                this.isLoading = false;
                try {
                    await axios.delete(`/api2/itemOrderBatches/${this.newBatch.id}`);
                } finally {
                    this.isLoading = false;
                }
            },
            async deleteItemOrders() {
                this.isSaving = true;
                try {
                    const itemOrderIds = this.itemOrders.map((x) => x.id);

                    if (itemOrderIds.length > 0) {
                        await axios.post("/api2/itemOrders/deleteMany", itemOrderIds);
                    }
                } finally {
                    this.isSaving = false;
                }
            },
            async deleteItemOrder(id) {
                await axios.delete("/api2/itemOrders", id);
            },
            async back() {
                this.isBackPressed = true;
                this.step = 1;
            },
            async forward() {
                await this.updateOrderBatch();
                this.step = 2;
            },
            async createOrderBatch() {
                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.isSaving) {
                    return;
                }
                if (this.isBackPressed) {
                    await this.forward();
                    return;
                }

                this.isSaving = true;
                try {
                    const result = await axios.post("/api/MagazineOrder/ItemOrderBatch", this.newBatch);

                    if (result.data) {
                        this.newBatch = result.data;
                        this.itemOrders = await this.generateItemOrders();
                        this.step = 2;
                    } else {
                        throw new Error("Failed to create order batch");
                    }
                } finally {
                    this.isSaving = false;
                }
            },
            async updateOrderBatch() {
                // Update OrderId and comments only

                this.isSaving = true;
                try {
                    const res = await axios.put("/api2/itemOrderBatches", this.newBatch);
                    if (res.status == 200 || res.status == 201) {
                        this.step = 2;
                    } else {
                        throw new Error("Failed to update order batch");
                    }
                } finally {
                    this.isSaving = false;
                    this.isBackPressed = false;
                }
            },
            async generateItemOrders() {
                const response = await axios.post("/api/MagazineOrder/GenerateItemOrders", this.newBatch);
                return response.data;
            },
            async save() {
                this.isSaving = true;
                try {
                    const childLocalItems = this.$refs.itemOrderTable.localItems;
                    const { data, status } = await axios.post("/api/MagazineOrder/UpsertItemOrders", childLocalItems);
                    if ([200, 201].includes(status)) {
                        this.items = data;
                        this.completeOrder();
                    } else {
                        throw new Error("Failed to save item orders");
                    }
                } finally {
                    this.isSaving = false;
                }
            },
            completeOrder() {
                this.snackbar = true;
                this.snackbarText = "Orders saved";
                this.isEditMode = true;
            },
            onClose() {
                this.$emit("close");
            },
            async cancel() {
                if (confirm("This will delete the order, are you sure you want to cancel?")) {
                    if (this.itemOrders.length > 0) {
                        await this.deleteItemOrders();
                    }
                    if (this.newBatch?.id) {
                        await this.deleteOrderBatch();
                    }
                    this.$emit("close");
                }
            },
            onUpdateLookback(newPeriod) {
                if (newPeriod.value == "custom") {
                    this.isCustomLookback = true;
                } else {
                    this.newBatch.usageLookBackPeriodEnd = nowIso();
                    this.newBatch.usageLookBackPeriodStart = DateTime.now().minus({ days: newPeriod.value }).toISO();
                    this.isCustomLookback = false;
                }
                localStorage.setItem("selectedLookbackPeriod", JSON.stringify(newPeriod));
            },
            onUpdateDeliveryLeadTimeDays(newDays) {
                localStorage.setItem("lastDeliveryLeadTimeDays", JSON.stringify(newDays));
            },
        },
    };
</script>
