<template>
    <confirmation-dialog
        v-model="localDialog"
        irreversible
        :irreversibleMessage="irreversibleMessage"
        title="Capacities Exceeded"
        max-width="600px"
        @confirm="$emit('confirm')"
    >
        <template #message>
            <v-list class="px-2">
                <v-list-item-group>
                    <v-list-item v-for="(item, index) in exceededCapacities" :key="index">
                        <v-list-item-icon>
                            <v-icon color="red" large>mdi-alert-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-capitalize text-h6">
                                {{ item.type }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-1">
                                {{ item.currentCapacity }}
                                / {{ item.totalCapacity }} exceeded by {{ item.exceedAmount }} ({{ item.unit }})
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </template>
    </confirmation-dialog>
</template>

<script>
    import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

    export default {
        components: { ConfirmationDialog },

        props: {
            value: {
                type: Boolean,
                default: false,
            },
            exceededCapacities: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            irreversibleMessage() {
                return `Capacity limits for ${this.exceededCapacities.length} type(s) have been exceeded.\nPlease review carefully before proceeding.`;
            },
            localDialog: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                },
            },
        },
    };
</script>
