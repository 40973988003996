import {
    buildCellOptions,
    buildHeaderOptions,
    getCellComponent,
    getFilterComponent,
    getHeaders,
    getTableData,
    applyForeignKeySorting,
    updateLookups,
    // TODO: automatic updates with SignalR
    // getLookupTypes,
} from "@/features/schemas/services/tableService";

import {
    canEdit,
    canUndelete,
    canDelete,
    canViewItem,
    undelete,
    deleteMany,
    undeleteMany,
} from "@/features/schemas/services/schemaApi";
import { equal, and } from "@/features/schemas/services/schemaFiltering";
import schemaMixin from "./schemaMixin";
import fixedValuesMixin from "./fixedValuesMixin";
import defaultValuesMixin from "./defaultValuesMixin";
import customFieldsMixin from "./dataTableCustomFieldsMixin";
import { hasProperty } from "@/services/objectUtility";
import { getItemRoute } from "../services/schemaRouteProvider";
import { isNullOrWhiteSpace, trim } from "@/services/stringUtility";
import { search } from "@/features/schemas/services/searcher";
import { debounce } from "@/services/debounce";

// TODO: automatic updates with SignalR
// import { subscribe } from "@/services/actionHub";

export default {
    mixins: [schemaMixin, fixedValuesMixin, defaultValuesMixin, customFieldsMixin],
    props: {
        filter: {
            type: Object,
            default: () => {},
        },
        recordState: {
            type: String,
            default: "active",
        },
        searchText: {
            type: String,
            default: "",
        },
        sortBy: {
            type: String,
            default: null,
        },
        direction: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            localRecordState: "active",
            options: {},
            tableData: {
                items: [],
                total: 0,
                lookups: {},
            },
            hasExtraActions: false,
            subscription: null,
            selectedItems: [],
            /** Visual pagination */
            page: 1,
        };
    },
    computed: {
        headers() {
            const combinedHeaders = [...this.entityHeaders, ...this.customFieldHeaders];
            if (!(this.canEditEntity || this.canViewEntity || this.hasExtraActions)) {
                return [combinedHeaders];
            }

            return combinedHeaders.concat({
                text: "",
                value: "actions",
                sortable: false,
                cellClass: "action-cell",
            });
        },

        fieldKeys() {
            return this.entityHeaders.map((h) => h.value);
        },
        fixedFields() {
            return Object.keys(this.fixedValues ?? {});
        },
        requestModel() {
            const fixedFilters = this.fixedFields.map((f) => equal(this.entityKey, f, this.fixedValues[f]));

            const filters = [...fixedFilters, this.filter].filter((f) => f != null);

            //See schemaFilterMixin and SchemaColumnFilter.vue
            if (this.schemaFilter) {
                filters.push(this.schemaFilter);
            }

            const filter = filters.length ? and(filters) : null;

            let options = this.options;
            if (options) {
                options.sortBy = applyForeignKeySorting(this.entityKey, options.sortBy);
            }
            if (!options?.sortBy && this.sortBy) {
                options = options ?? {};
                options.sortBy = this.sortBy;
                options.direction = this.direction;
            }
            return { filter, ...options, recordState: this.localRecordState };
        },
        canEditEntity() {
            // We can check for editability by seeing if there's a listener for the edit event.
            return canEdit(this.entityKey) && !!this.$listeners?.edit;
        },
        canViewEntity() {
            return canViewItem(this.entityKey);
        },
        canDeleteEntity() {
            return this.localRecordState == "active" && canDelete(this.entityKey);
        },
        canUndeleteEntity() {
            return this.localRecordState == "deleted" && canUndelete(this.entityKey);
        },
        entityHeaders() {
            if (this.entityKey) {
                return getHeaders(this.entityKey).filter(
                    (h) => !this.isFixedValue(h.value) && !this.isDefaultValue(h.value)
                );
            }
            return [];
        },
    },
    watch: {
        requestModel: {
            immediate: true,
            async handler(value, oldValue) {
                if (value != null && oldValue != null && JSON.stringify(value) === JSON.stringify(oldValue)) {
                    return;
                }

                await this.refresh();
            },
        },
        searchText: {
            immediate: false,
            async handler() {
                if (isNullOrWhiteSpace(trim(this.searchText, '"'))) {
                    await this.refresh();
                    return;
                }
                this.debouncedSearch();
            },
        },
        // When we switch between "Active" and "Inactive" records we want to reset pagination.
        recordState: {
            async handler() {
                this.page = 1;
                this.options.skip = 0;
                // We want the "requestModel" computed function to only run once,
                // hence this local hack. Otherwise record state would change,
                // request Model fires, then this skip option change will fire.
                this.localRecordState = this.recordState;
            },
        },
    },
    mounted() {
        this.hasExtraActions = hasProperty(this.$scopedSlots, "extra-actions");
        // TODO: automatic updates with SignalR
        // let entityKeys = [this.entityKey, ...getLookupTypes(this.entityKey)];
        // this.subscription = subscribe(this.receiveMessages, entityKeys);
    },
    destroyed() {
        // TODO: automatic updates with SignalR
        // this.subscription.unsubscribe();
    },
    methods: {
        getCellComponent(fieldKey) {
            return getCellComponent(this.entityKey, fieldKey);
        },
        getFilterComponent(fieldKey) {
            return getFilterComponent(this.entityKey, fieldKey);
        },
        getHeaderOptions(fieldKey) {
            return buildHeaderOptions(this.entityKey, fieldKey);
        },
        getCellOptions(fieldKey) {
            return buildCellOptions(this.tableData, fieldKey);
        },
        async refresh() {
            if (!this.options?.take) {
                return;
            }

            if (isNullOrWhiteSpace(trim(this.searchText, '"'))) {
                this.tableData = await getTableData(this.entityKey, this.requestModel, true);
                return;
            }

            await this.search();
        },
        viewItem(item) {
            var itemRoute = getItemRoute(this.entityKey);
            if (itemRoute == null) {
                return;
            }
            this.$router.push({
                name: itemRoute.name,
                params: { itemId: item.id },
            });
        },
        async receiveMessages(messages) {
            // If this entity has changes, it's simpliest to refresh the data with the current
            // filter. This is because a change may impact filtering, sorting and pagination.
            if (messages.some((m) => m.entityKey == this.entityKey)) {
                await this.refresh();
                return;
            }

            // If there are changes to my lookup types, then determine if any lookup data should be
            // refreshed.
            await updateLookups(this.tableData.lookups, messages);
        },
        async undelete(item) {
            await undelete(this.entityKey, item.id);
            // Because of server pagination, just removing the item with javascript would not reflect the true state correctly.
            // Simplest to just refresh.
            await this.refresh();
        },
        async search() {
            let searchText = this.searchText;

            let options = this.requestModel;
            options.includeLookups = true;
            options.allFields = true;

            let results = await search(this.entityKey, searchText, options);

            // Avoid showing previous search if results arrive out of order.
            if (searchText === this.searchText) {
                this.tableData = results;
            }
        },
        async deleteMany(showConfirm = true) {
            if (showConfirm) {
                if (!confirm("Are you sure you want to deactivate the selected items?")) {
                    return;
                }
            }

            const ids = this.selectedItems.map((x) => x.id);
            await deleteMany(this.entityKey, ids);
            this.selectedItems = [];
            await this.refresh();
        },
        async undeleteMany() {
            const ids = this.selectedItems.map((x) => x.id);
            await undeleteMany(this.entityKey, ids);
            this.selectedItems = [];
            await this.refresh();
        },
    },
    created() {
        this.debouncedSearch = debounce(() => this.search(), 250);
    },
};
