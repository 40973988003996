import { render, staticRenderFns } from "./SchemaAddColumn.vue?vue&type=template&id=1ccf4bad&"
import script from "./SchemaAddColumn.vue?vue&type=script&lang=js&"
export * from "./SchemaAddColumn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports