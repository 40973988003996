<template>
    <inline-svg v-if="iconPath != null" ref="component" :key="key" :src="iconPath" @loaded="color" />
</template>

<script>
    import InlineSvg from "vue-inline-svg";
    import { camelToPascalCase } from "@/services/stringUtility";

    const properties = ["primaryColor", "secondaryColor", "backgroundColor"];

    export default {
        components: {
            InlineSvg,
        },

        props: {
            icon: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                key: 0,
            };
        },

        computed: {
            iconPath() {
                try {
                    return require(`@/assets/icons/${this.formatIconName(this.icon.iconImage)}.svg`);
                } catch {
                    return null;
                }
            },
            primaryColor() {
                return this.icon.primaryColor;
            },
            secondaryColor() {
                return this.icon.secondaryColor;
            },
            backgroundColor() {
                return this.icon.backgroundColor;
            },
        },

        methods: {
            formatIconName(iconName) {
                if (!iconName) return "";
                return camelToPascalCase(iconName);
            },
            color() {
                var icon = this.$refs.component.$el;
                if (!icon) return;

                //TODO?: Possibly multiple <g></g> elements within the SVG
                var g = icon?.getElementsByTagName("g")[0];
                if (!g) return;

                //Get all path elements
                const paths = Array.from(g.getElementsByTagName("path")).filter((p) => p.hasAttribute("group"));

                //Return a map; eg {"primaryColor", [...SVGPathElement]}
                let groups = this.getGroups(paths);

                for (const property of properties) {
                    //Get elements from group
                    let elements = groups.get(property);

                    if (!elements) continue;

                    for (let j = 0; j < elements.length; j++) {
                        const element = elements[j];

                        const color = this[property];

                        if (color) {
                            element.setAttribute("fill", color);
                        }
                    }
                }
            },
            getGroups(paths) {
                const map = new Map();

                for (let path of paths) {
                    let group = path.getAttribute("group");

                    if (!group) continue; //Skip if no group attribute exists

                    const collection = map.get(group);

                    //If collection doesn't exist, create a new array with the path element
                    if (!collection) {
                        map.set(group, [path]);
                    }
                    //Else push section into it
                    else {
                        collection.push(path);
                    }
                }

                return map;
            },
        },

        watch: {
            icon: {
                handler() {
                    this.key++;
                },
                deep: true,
            },
        },
    };
</script>
