<template>
    <div class="py-4">
        <v-form ref="form" @submit="addCategoryItem" @submit.prevent>
            <div class="text-caption pb-2">
                The inputs below allow you to populate the defaults for new category items.
            </div>
            <v-row>
                <v-col cols="8">
                    <app-text-field
                        v-model="itemDescription"
                        label="Item Description"
                        :rules="[(v) => !!v || 'is required']"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <app-text-field
                        v-model.number="itemQuantityPerBox"
                        label="Quantity Per Box"
                        type="number"
                        :rules="[(v) => v > 0 || isNullOrWhiteSpace(v) || 'must be greater than 0']"
                    />
                </v-col>
                <v-col cols="4">
                    <app-text-field
                        v-model.number="itemQuantityPerPallet"
                        label="Quantity Per Pallet"
                        type="number"
                        :rules="[(v) => v > 0 || isNullOrWhiteSpace(v) || 'must be greater than 0']"
                    />
                </v-col>
                <v-col cols="4">
                    <app-text-field
                        v-model.number="itemWeightPerItem"
                        label="Weight Per Item"
                        type="number"
                        :rules="[(v) => v > 0 || isNullOrWhiteSpace(v) || 'must be greater than 0']"
                    />
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col align-self="center">
                    <v-checkbox v-model="computedShowIcon" label="Show Det Clips" hide-details></v-checkbox>
                </v-col>
                <v-col align="center">
                    <app-button @click="addCategoryItem">Add Category Item</app-button>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-form>

        <div style="height: 500px; overflow: auto">
            <v-data-table
                :headers="computedHeaders"
                :items="value"
                hide-default-footer
                :footer-props="{ 'items-per-page-options': [-1] }"
            >
                <template #item.icon="{ item }">
                    <v-menu offset-x center :close-on-content-click="false">
                        <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="d-flex">
                                <exm-icon
                                    style="height: 36px; width: 36px"
                                    :icon="{
                                        iconImage: 'detClip',
                                        primaryColor: null,
                                        secondaryColor: null,
                                        backgroundColor: item.backgroundColor,
                                    }"
                                />
                            </div>
                        </template>
                        <v-color-picker
                            show-swatches
                            swatches-max-height="300px"
                            mode="hexa"
                            v-model="item.backgroundColor"
                            flat
                        />
                    </v-menu>
                </template>

                <template #item.name="{ item }">
                    <app-text-field v-model="item.name" hide-details :rules="[(v) => !!v || 'is required']" />
                </template>

                <template #item.description="{ item }">
                    <app-text-field v-model="item.description" hide-details />
                </template>

                <template #item.quantityPerBox="{ item }">
                    <app-text-field
                        v-model.number="item.quantityPerBox"
                        type="number"
                        hide-details
                        :rules="[(v) => v > 0 || isNullOrWhiteSpace(v)]"
                    />
                </template>

                <template #item.quantityPerPallet="{ item }">
                    <app-text-field
                        v-model.number="item.quantityPerPallet"
                        type="number"
                        hide-details
                        :rules="[(v) => v > 0 || isNullOrWhiteSpace(v)]"
                    />
                </template>

                <template #item.weightPerItem="{ item }">
                    <app-text-field
                        v-model.number="item.weightPerItem"
                        type="number"
                        hide-details
                        :rules="[(v) => v > 0 || isNullOrWhiteSpace(v)]"
                    />
                </template>

                <template #item.actions="{ index }">
                    <v-icon @click="deleteItem(index)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import ExmIcon from "@/components/ExmIcon.vue";
    import { isNullOrWhiteSpace } from "@/services/stringUtility";

    export default {
        components: { ExmIcon },
        props: { value: Array, categoryName: String, showIcon: Boolean },
        data() {
            return {
                itemDescription: null,
                itemQuantityPerBox: null,
                itemQuantityPerPallet: null,
                itemWeightPerItem: null,
                headers: [
                    {
                        text: "Icon",
                        value: "icon",
                        sortable: false,
                        align: "center",
                        visible: () => this.showIcon,
                    },
                    {
                        text: "Name",
                        value: "name",
                    },
                    {
                        text: "Description",
                        value: "description",
                    },
                    {
                        text: "Quantity Per Box",
                        value: "quantityPerBox",
                        width: "1px",
                    },
                    {
                        text: "Quantity Per Pallet",
                        value: "quantityPerPallet",
                        width: "1px",
                    },
                    {
                        text: "Weight Per Item",
                        value: "weightPerItem",
                        width: "1px",
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        align: "center",
                        width: "1px",
                    },
                ],
            };
        },

        methods: {
            isNullOrWhiteSpace,
            addCategoryItem() {
                if (!this.$refs.form.validate()) {
                    return;
                }

                const numElements = this.value.length;
                const lastBackgroundColor = numElements < 1 ? "#000000" : this.value[numElements - 1].backgroundColor;

                const inventoryItem = {
                    id: numElements,
                    name: `${numElements}`,
                    description: this.itemDescription,
                    backgroundColor: lastBackgroundColor,
                    weightPerItem: this.itemWeightPerItem,
                    quantityPerBox: this.itemQuantityPerBox,
                    quantityPerPallet: this.itemQuantityPerPallet,
                    priority: 0,
                };
                this.value.push(inventoryItem);
            },

            deleteItem(index) {
                this.value.splice(index, 1);
            },
        },

        computed: {
            computedHeaders() {
                return this.headers.filter((x) => x.visible === undefined || x.visible());
            },

            computedShowIcon: {
                get() {
                    return this.showIcon;
                },
                set(value) {
                    this.$emit("update:showIcon", value);
                },
            },
        },

        watch: {
            categoryName: {
                handler() {
                    this.itemDescription = this.categoryName;
                },
            },
        },
    };
</script>

<style scoped>
    ::v-deep .v-small-dialog__activator__content {
        display: flex;
    }

    ::v-deep .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
    }
</style>
