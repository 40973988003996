var T = { exports: {} }, m = typeof Reflect == "object" ? Reflect : null, D = m && typeof m.apply == "function" ? m.apply : function(t, e, n) {
  return Function.prototype.apply.call(t, e, n);
}, y;
m && typeof m.ownKeys == "function" ? y = m.ownKeys : Object.getOwnPropertySymbols ? y = function(t) {
  return Object.getOwnPropertyNames(t).concat(Object.getOwnPropertySymbols(t));
} : y = function(t) {
  return Object.getOwnPropertyNames(t);
};
function Y(o) {
  console && console.warn && console.warn(o);
}
var C = Number.isNaN || function(t) {
  return t !== t;
};
function l() {
  l.init.call(this);
}
T.exports = l;
T.exports.once = k;
l.EventEmitter = l;
l.prototype._events = void 0;
l.prototype._eventsCount = 0;
l.prototype._maxListeners = void 0;
var S = 10;
function v(o) {
  if (typeof o != "function")
    throw new TypeError('The "listener" argument must be of type Function. Received type ' + typeof o);
}
Object.defineProperty(l, "defaultMaxListeners", {
  enumerable: !0,
  get: function() {
    return S;
  },
  set: function(o) {
    if (typeof o != "number" || o < 0 || C(o))
      throw new RangeError('The value of "defaultMaxListeners" is out of range. It must be a non-negative number. Received ' + o + ".");
    S = o;
  }
});
l.init = function() {
  (this._events === void 0 || this._events === Object.getPrototypeOf(this)._events) && (this._events = /* @__PURE__ */ Object.create(null), this._eventsCount = 0), this._maxListeners = this._maxListeners || void 0;
};
l.prototype.setMaxListeners = function(t) {
  if (typeof t != "number" || t < 0 || C(t))
    throw new RangeError('The value of "n" is out of range. It must be a non-negative number. Received ' + t + ".");
  return this._maxListeners = t, this;
};
function P(o) {
  return o._maxListeners === void 0 ? l.defaultMaxListeners : o._maxListeners;
}
l.prototype.getMaxListeners = function() {
  return P(this);
};
l.prototype.emit = function(t) {
  for (var e = [], n = 1; n < arguments.length; n++)
    e.push(arguments[n]);
  var s = t === "error", i = this._events;
  if (i !== void 0)
    s = s && i.error === void 0;
  else if (!s)
    return !1;
  if (s) {
    var r;
    if (e.length > 0 && (r = e[0]), r instanceof Error)
      throw r;
    var a = new Error("Unhandled error." + (r ? " (" + r.message + ")" : ""));
    throw a.context = r, a;
  }
  var h = i[t];
  if (h === void 0)
    return !1;
  if (typeof h == "function")
    D(h, this, e);
  else
    for (var d = h.length, c = w(h, d), n = 0; n < d; ++n)
      D(c[n], this, e);
  return !0;
};
function R(o, t, e, n) {
  var s, i, r;
  if (v(e), i = o._events, i === void 0 ? (i = o._events = /* @__PURE__ */ Object.create(null), o._eventsCount = 0) : (i.newListener !== void 0 && (o.emit(
    "newListener",
    t,
    e.listener ? e.listener : e
  ), i = o._events), r = i[t]), r === void 0)
    r = i[t] = e, ++o._eventsCount;
  else if (typeof r == "function" ? r = i[t] = n ? [e, r] : [r, e] : n ? r.unshift(e) : r.push(e), s = P(o), s > 0 && r.length > s && !r.warned) {
    r.warned = !0;
    var a = new Error("Possible EventEmitter memory leak detected. " + r.length + " " + String(t) + " listeners added. Use emitter.setMaxListeners() to increase limit");
    a.name = "MaxListenersExceededWarning", a.emitter = o, a.type = t, a.count = r.length, Y(a);
  }
  return o;
}
l.prototype.addListener = function(t, e) {
  return R(this, t, e, !1);
};
l.prototype.on = l.prototype.addListener;
l.prototype.prependListener = function(t, e) {
  return R(this, t, e, !0);
};
function X() {
  if (!this.fired)
    return this.target.removeListener(this.type, this.wrapFn), this.fired = !0, arguments.length === 0 ? this.listener.call(this.target) : this.listener.apply(this.target, arguments);
}
function I(o, t, e) {
  var n = { fired: !1, wrapFn: void 0, target: o, type: t, listener: e }, s = X.bind(n);
  return s.listener = e, n.wrapFn = s, s;
}
l.prototype.once = function(t, e) {
  return v(e), this.on(t, I(this, t, e)), this;
};
l.prototype.prependOnceListener = function(t, e) {
  return v(e), this.prependListener(t, I(this, t, e)), this;
};
l.prototype.removeListener = function(t, e) {
  var n, s, i, r, a;
  if (v(e), s = this._events, s === void 0)
    return this;
  if (n = s[t], n === void 0)
    return this;
  if (n === e || n.listener === e)
    --this._eventsCount === 0 ? this._events = /* @__PURE__ */ Object.create(null) : (delete s[t], s.removeListener && this.emit("removeListener", t, n.listener || e));
  else if (typeof n != "function") {
    for (i = -1, r = n.length - 1; r >= 0; r--)
      if (n[r] === e || n[r].listener === e) {
        a = n[r].listener, i = r;
        break;
      }
    if (i < 0)
      return this;
    i === 0 ? n.shift() : H(n, i), n.length === 1 && (s[t] = n[0]), s.removeListener !== void 0 && this.emit("removeListener", t, a || e);
  }
  return this;
};
l.prototype.off = l.prototype.removeListener;
l.prototype.removeAllListeners = function(t) {
  var e, n, s;
  if (n = this._events, n === void 0)
    return this;
  if (n.removeListener === void 0)
    return arguments.length === 0 ? (this._events = /* @__PURE__ */ Object.create(null), this._eventsCount = 0) : n[t] !== void 0 && (--this._eventsCount === 0 ? this._events = /* @__PURE__ */ Object.create(null) : delete n[t]), this;
  if (arguments.length === 0) {
    var i = Object.keys(n), r;
    for (s = 0; s < i.length; ++s)
      r = i[s], r !== "removeListener" && this.removeAllListeners(r);
    return this.removeAllListeners("removeListener"), this._events = /* @__PURE__ */ Object.create(null), this._eventsCount = 0, this;
  }
  if (e = n[t], typeof e == "function")
    this.removeListener(t, e);
  else if (e !== void 0)
    for (s = e.length - 1; s >= 0; s--)
      this.removeListener(t, e[s]);
  return this;
};
function M(o, t, e) {
  var n = o._events;
  if (n === void 0)
    return [];
  var s = n[t];
  return s === void 0 ? [] : typeof s == "function" ? e ? [s.listener || s] : [s] : e ? G(s) : w(s, s.length);
}
l.prototype.listeners = function(t) {
  return M(this, t, !0);
};
l.prototype.rawListeners = function(t) {
  return M(this, t, !1);
};
l.listenerCount = function(o, t) {
  return typeof o.listenerCount == "function" ? o.listenerCount(t) : b.call(o, t);
};
l.prototype.listenerCount = b;
function b(o) {
  var t = this._events;
  if (t !== void 0) {
    var e = t[o];
    if (typeof e == "function")
      return 1;
    if (e !== void 0)
      return e.length;
  }
  return 0;
}
l.prototype.eventNames = function() {
  return this._eventsCount > 0 ? y(this._events) : [];
};
function w(o, t) {
  for (var e = new Array(t), n = 0; n < t; ++n)
    e[n] = o[n];
  return e;
}
function H(o, t) {
  for (; t + 1 < o.length; t++)
    o[t] = o[t + 1];
  o.pop();
}
function G(o) {
  for (var t = new Array(o.length), e = 0; e < t.length; ++e)
    t[e] = o[e].listener || o[e];
  return t;
}
function k(o, t) {
  return new Promise(function(e, n) {
    function s(r) {
      o.removeListener(t, i), n(r);
    }
    function i() {
      typeof o.removeListener == "function" && o.removeListener("error", s), e([].slice.call(arguments));
    }
    L(o, t, i, { once: !0 }), t !== "error" && Z(o, s, { once: !0 });
  });
}
function Z(o, t, e) {
  typeof o.on == "function" && L(o, "error", t, e);
}
function L(o, t, e, n) {
  if (typeof o.on == "function")
    n.once ? o.once(t, e) : o.on(t, e);
  else if (typeof o.addEventListener == "function")
    o.addEventListener(t, function s(i) {
      n.once && o.removeEventListener(t, s), e(i);
    });
  else
    throw new TypeError('The "emitter" argument must be of type EventEmitter. Received type ' + typeof o);
}
var E = T.exports;
class N {
  constructor(t) {
    this._scale = 1, this._translation = { x: 0, y: 0 }, this.context = t, this.centerGrid();
  }
  get scale() {
    return this._scale;
  }
  get translation() {
    return this._translation;
  }
  setScale(t) {
    const e = this.context.getOptions(), n = this._scale;
    this._scale = Math.max(e.minZoom ?? 0.5, Math.min(e.maxZoom ?? 2, t));
    const s = this._scale / n;
    this._translation.x *= s, this._translation.y *= s, this.constrainTranslation();
  }
  setTranslation(t, e) {
    this._translation = { x: t, y: e }, this.constrainTranslation();
  }
  centerGrid() {
    this.setTranslation(0, 0), this.setScale(1);
  }
  getGridPointFromCoordinates(t, e) {
    const n = this.context.getOptions(), s = this.context.getRenderer(), i = this.scale, r = this.translation, a = s.dpr, { cellSize: h, gridSize: d } = n, { width: c, height: g } = s.gridCanvas;
    t /= a, e /= a, t -= c / (2 * a), e -= g / (2 * a), t -= r.x, e -= r.y, t /= i, e /= i, t += d * h / 2, e += d * h / 2;
    const f = Math.round(t / h) * h, x = Math.round(e / h) * h, p = d * h, z = Math.max(0, Math.min(f, p)), _ = Math.max(0, Math.min(x, p));
    return { x: z, y: _ };
  }
  gridToCanvasCoordinates(t, e, n) {
    const { gridSize: s, cellSize: i } = this.context.getOptions();
    return {
      x: (t.x - s * i / 2) * this.scale + e / 2 + this.translation.x,
      y: (t.y - s * i / 2) * this.scale + n / 2 + this.translation.y
    };
  }
  constrainPointToGrid(t) {
    const { gridSize: e, cellSize: n } = this.context.getOptions(), s = e * n;
    return {
      x: Math.max(0, Math.min(s, t.x)),
      y: Math.max(0, Math.min(s, t.y))
    };
  }
  constrainTranslation() {
    const { gridCanvas: t, dpr: e } = this.context.getRenderer(), { width: n, height: s } = t, { gridSize: i, cellSize: r } = this.context.getOptions(), a = i * r * this._scale, h = i * r * this._scale, d = Math.max(0, (a - n / e) / 2), c = Math.max(0, (h - s / e) / 2);
    this._translation.x = Math.max(-d, Math.min(d, this._translation.x)), this._translation.y = Math.max(-c, Math.min(c, this._translation.y));
  }
  getScaledGridPoint(t, e) {
    const { gridCanvas: n, dpr: s } = this.context.getRenderer(), i = n.getBoundingClientRect(), r = (t - i.left) * s, a = (e - i.top) * s;
    return this.getGridPointFromCoordinates(r, a);
  }
  getBounds() {
    const { gridCanvas: t, dpr: e } = this.context.getRenderer(), { width: n, height: s } = t, { gridSize: i, cellSize: r } = this.context.getOptions(), a = i * r * this._scale, h = i * r * this._scale, d = Math.max(0, (a - n / e) / 2), c = Math.max(0, (h - s / e) / 2);
    return {
      minX: -d,
      maxX: d,
      minY: -c,
      maxY: c
    };
  }
  isPointOverCanvas(t, e) {
    const { gridCanvas: n } = this.context.getRenderer(), s = n.getBoundingClientRect();
    return t >= s.left && t <= s.right && e >= s.top && e <= s.bottom;
  }
  isPositionOccupied(t, e, n = null) {
    return this.context.getData().some((i) => i !== n && i.X === t && i.Y === e);
  }
  isPointInRegion(t, e) {
    if (!(e != null && e.start) || !(e != null && e.end))
      return !1;
    const { x: n, y: s } = t, { start: i, end: r } = e, a = Math.min(i.x, r.x), h = Math.max(i.x, r.x), d = Math.min(i.y, r.y), c = Math.max(i.y, r.y);
    return n >= a && n <= h && s >= d && s <= c;
  }
  getDetonatorAtPosition(t, e) {
    return this.context.getData().find((s) => s.X === t && s.Y === e) || null;
  }
  getDetonatorsInRegion(t) {
    return this.context.getData().filter((n) => this.isPointInRegion({ x: n.X, y: n.Y }, t));
  }
}
var u = /* @__PURE__ */ ((o) => (o[o.Normal = 0] = "Normal", o[o.Edit = 1] = "Edit", o[o.Delete = 2] = "Delete", o[o.Region = 3] = "Region", o))(u || {});
class W {
  constructor(t) {
    this._isCreating = !1, this._isDraggingRegion = !1, this.lastRegionPosition = null, this.context = t;
  }
  get isCreating() {
    return this._isCreating;
  }
  get isDraggingRegion() {
    return this._isDraggingRegion;
  }
  start(t) {
    const { region: e } = this.context.state, n = this.context.getGrid();
    if (e != null && e.start && (e != null && e.end)) {
      n.isPointInRegion(t, e) && (this._isDraggingRegion = !0, this.context.state.setDragOffset({
        x: t.x - e.start.x,
        y: t.y - e.start.y
      }), this.lastRegionPosition = { ...e.start });
      return;
    }
    this._isCreating = !0, this.context.state.setRegion({
      start: t,
      end: t
    });
  }
  update(t, e) {
    const { dragOffset: n } = this.context.state;
    if (this.isCreating)
      return e.end = t, this.updateSelectedDetonators(), !0;
    if (this.isDraggingRegion && e.start && e.end && this.lastRegionPosition) {
      const s = e.end.x - e.start.x, i = e.end.y - e.start.y, r = t.x - n.x, a = t.y - n.y, { gridSize: h, cellSize: d } = this.context.getOptions(), c = h * d - s, g = h * d - i;
      return e.start = {
        x: Math.max(0, Math.min(r, c)),
        y: Math.max(0, Math.min(a, g))
      }, e.end = {
        x: e.start.x + s,
        y: e.start.y + i
      }, this.updateDetonatorPositions(e.start), !0;
    }
    return !1;
  }
  updateDetonatorPositions(t) {
    if (!this.lastRegionPosition)
      return;
    const { selectedDetonatorIds: e } = this.context.state, n = t.x - this.lastRegionPosition.x, s = t.y - this.lastRegionPosition.y, { gridSize: i, cellSize: r } = this.context.getOptions(), a = i * r, d = this.context.getData().filter((c) => e.includes(c.Id));
    for (const c of d) {
      const g = Math.max(0, Math.min(a, c.X + n)), f = Math.max(0, Math.min(a, c.Y + s));
      (g !== c.X || f !== c.Y) && (c.X = g, c.Y = f);
    }
    this.lastRegionPosition = t;
  }
  finish() {
    const { state: t } = this.context;
    this.isDraggingRegion && this.updateDetonators(), this.isCreating && t.selectedDetonatorIds.length === 0 && this.clearRegion(), this._isCreating = !1, this._isDraggingRegion = !1, this.lastRegionPosition = null;
  }
  clearRegion() {
    const { state: t } = this.context;
    t.setRegion(null), t.setDragOffset({ x: 0, y: 0 }), t.clearSelectedDetonators(), this._isCreating = !1, this._isDraggingRegion = !1, this.context.getRenderer().renderRegion();
  }
  updateDetonators() {
    const { selectedDetonatorIds: t } = this.context.state;
    if (t.length === 0)
      return;
    const e = this.removeUnselectedDetonatorsInRegion(), s = this.context.getData().filter((r) => t.includes(r.Id));
    this.context.emit("batchEdit", s, e);
    const i = this.context.getRenderer();
    i.renderRegion(), i.renderDetonators();
  }
  /**
   * Removes unselected detonators within the current region.
   *
   * Iterates through all detonators in the data set and removes
   * those that are within the bounds of the current region but not included
   * in the selected detonators array
   */
  removeUnselectedDetonatorsInRegion() {
    const { region: t, selectedDetonatorIds: e } = this.context.state;
    if (!t || !t.start || !t.end)
      return;
    const n = this.context.getData(), s = {
      minX: Math.min(t.start.x, t.end.x),
      maxX: Math.max(t.start.x, t.end.x),
      minY: Math.min(t.start.y, t.end.y),
      maxY: Math.max(t.start.y, t.end.y)
    }, i = [];
    for (let r = n.length - 1; r >= 0; r--) {
      const a = n[r];
      a.X >= s.minX && a.X <= s.maxX && a.Y >= s.minY && a.Y <= s.maxY && !e.includes(a.Id) && (n.splice(r, 1), i.push(a));
    }
    return i;
  }
  updateSelectedDetonators() {
    const { state: t } = this.context;
    if (!t.region) {
      t.clearSelectedDetonators();
      return;
    }
    const n = this.context.getGrid().getDetonatorsInRegion(t.region);
    t.setSelectedDetonators(n);
  }
}
class O {
  constructor(t, e) {
    this.dragStartTime = null, this.dragTimeThreshold = 100, this.preventClickOrTap = !1, this.boundaryPanThreshold = 16, this.boundaryPanSpeed = 5, this.boundaryPanInterval = null, this.context = t, this.zoom = e, this.regionInteraction = new W(t);
  }
  handlePan(t, e) {
    const n = this.context.getGrid(), s = n.translation;
    n.setTranslation(s.x + t, s.y + e), this.context.getRenderer().render();
  }
  checkBoundary(t, e) {
    const { gridCanvas: n } = this.context.getRenderer(), s = n.getBoundingClientRect();
    let i = 0, r = 0;
    t - s.left < this.boundaryPanThreshold && (i = this.boundaryPanSpeed), s.right - t < this.boundaryPanThreshold && (i = -this.boundaryPanSpeed), e - s.top < this.boundaryPanThreshold && (r = this.boundaryPanSpeed), s.bottom - e < this.boundaryPanThreshold && (r = -this.boundaryPanSpeed), i !== 0 || r !== 0 ? this.startBoundaryPan(i, r) : this.stopBoundaryPan();
  }
  startBoundaryPan(t, e) {
    this.boundaryPanInterval == null && (this.boundaryPanInterval = window.setInterval(() => {
      const n = this.context.getGrid(), s = n.translation, i = n.getBounds(), r = Math.max(i.minX, Math.min(i.maxX, s.x + t)), a = Math.max(i.minY, Math.min(i.maxY, s.y + e));
      (r !== s.x || a !== s.y) && this.handlePan(r - s.x, a - s.y), r === s.x && a === s.y && this.stopBoundaryPan();
    }, 16));
  }
  stopBoundaryPan() {
    this.boundaryPanInterval !== null && (window.clearInterval(this.boundaryPanInterval), this.boundaryPanInterval = null);
  }
  dragDetonator(t) {
    const { selectedDetonator: e } = this.context.state;
    if (!e)
      return;
    const n = this.context.getGrid(), s = n.getScaledGridPoint(t.clientX, t.clientY);
    if (n.isPositionOccupied(s.x, s.y, e))
      return;
    e.X = s.x, e.Y = s.y, this.context.getRenderer().renderDetonators();
  }
  handleClickOrTap(t) {
    const { state: e } = this.context, { mode: n } = e;
    if (this.preventClickOrTap || n === u.Edit || n === u.Region) {
      t instanceof Event && t.preventDefault();
      return;
    }
    const s = this.context.getRenderer(), i = this.context.getGrid(), r = i.getScaledGridPoint(t.clientX, t.clientY), a = i.getDetonatorAtPosition(r.x, r.y);
    a && n === u.Delete ? (this.context.emit("delete", a), s.render()) : n === u.Normal && (this.context.emit("add", r), s.render());
  }
  handleInteractionMove(t) {
    const { state: e } = this.context;
    if (!e.isDragging || !e.lastInteractionPoint)
      return;
    const n = Date.now();
    this.dragStartTime && n - this.dragStartTime > this.dragTimeThreshold && (this.preventClickOrTap = !0);
    const s = t.clientX - e.lastInteractionPoint.x, i = t.clientY - e.lastInteractionPoint.y, a = this.context.getGrid().getScaledGridPoint(t.clientX, t.clientY), h = this.context.getRenderer();
    e.mode === u.Region && e.region ? this.regionInteraction.update(a, e.region) ? (this.checkBoundary(t.clientX, t.clientY), h.renderDetonators(), h.renderRegion()) : !this.regionInteraction.isCreating && !this.regionInteraction.isDraggingRegion && (this.handlePan(s, i), e.setIsPanning(!0)) : e.mode === u.Edit && e.selectedDetonator ? (this.dragDetonator(t), this.checkBoundary(t.clientX, t.clientY)) : (this.handlePan(s, i), e.setIsPanning(!0)), e.setLastInteractionPoint({ x: t.clientX, y: t.clientY });
  }
  finishEdit() {
    const { selectedDetonator: t, lastInteractionPoint: e } = this.context.state;
    !t || !e || this.context.emit("edit", t);
  }
  resetState() {
    const { state: t } = this.context;
    t.setIsPanning(!1), t.setIsDragging(!1), t.setSelectedDetonator(null), t.setLastInteractionPoint(null), this.dragStartTime = null, this.preventClickOrTap = !1, this.stopBoundaryPan(), this.context.getRenderer().renderDetonators();
  }
}
class B extends O {
  constructor(t, e) {
    super(t, e), this.canvasEvents = [
      { event: "mousedown", handler: (n) => this.onMouseDown(n) },
      { event: "dblclick", handler: () => this.onDoubleClick() },
      { event: "wheel", handler: (n) => this.onWheel(n) },
      { event: "mousemove", handler: (n) => this.handleHoverMove(n) },
      { event: "mouseleave", handler: () => this.handleHoverLeave() }
    ], this.setupEventListeners();
  }
  setupEventListeners() {
    const { detonatorCanvas: t } = this.context.getRenderer();
    for (const e of this.canvasEvents)
      t.addEventListener(e.event, e.handler.bind(this));
    document.addEventListener("mousemove", this.onMouseMove.bind(this)), document.addEventListener("mouseup", this.onMouseUp.bind(this));
  }
  onMouseDown(t) {
    const { state: e } = this.context;
    e.setIsDragging(!0), e.setLastInteractionPoint({ x: t.clientX, y: t.clientY }), this.dragStartTime = Date.now(), this.preventClickOrTap = !1;
    const n = this.context.getGrid(), s = n.getScaledGridPoint(t.clientX, t.clientY);
    if (e.mode === u.Region)
      this.regionInteraction.start(s);
    else if (e.mode === u.Edit) {
      const i = n.getDetonatorAtPosition(s.x, s.y);
      e.setSelectedDetonator(i);
    }
  }
  onMouseMove(t) {
    this.handleInteractionMove(t);
  }
  onMouseUp(t) {
    const { state: e } = this.context, n = this.context.getGrid();
    e.mode === u.Region ? this.regionInteraction.finish() : e.mode === u.Edit ? this.finishEdit() : !this.preventClickOrTap && n.isPointOverCanvas(t.clientX, t.clientY) && this.handleClickOrTap(t), this.resetState();
  }
  handleHoverMove(t) {
    const { mode: e, isPanning: n, region: s } = this.context.state, i = this.context.getGrid(), r = i.getScaledGridPoint(t.clientX, t.clientY), a = this.context.getRenderer();
    if (e === u.Normal && a.renderHover(r), e === u.Region && !n) {
      const h = i.isPointInRegion(r, s) ? "grab" : "default";
      a.updateCursorStyle(a.detonatorCanvas, h);
    }
  }
  handleHoverLeave() {
    this.context.getRenderer().clearHover();
  }
  onWheel(t) {
    t.preventDefault();
    const e = this.zoom.getZoomSpeed(), n = Math.exp(-t.deltaY * e);
    this.zoom.applyZoom(t.clientX, t.clientY, n);
  }
  onDoubleClick() {
    const { mode: t } = this.context.state;
    if (t === u.Region) {
      this.regionInteraction.clearRegion();
      const e = this.context.getRenderer();
      e.renderRegion(), e.renderDetonators(), e.updateCursorStyle(e.regionCanvas, "default");
    }
  }
  destroy() {
    const { detonatorCanvas: t } = this.context.getRenderer();
    for (const e of this.canvasEvents)
      t.removeEventListener(e.event, e.handler.bind(this));
    document.removeEventListener("mousemove", this.onMouseMove.bind(this)), document.removeEventListener("mouseup", this.onMouseUp.bind(this));
  }
}
class A extends O {
  constructor(t, e) {
    super(t, e), this.lastTouch = null, this.lastPinchDistance = null, this.lastPinchCenter = null, this.isSingleTouch = !1, this.lastTapTime = 0, this.doubleTapDelay = 300, this.lastTapPosition = null, this.doubleTapThreshold = 20, this.multiTouchDelay = 100, this.touchTimer = null, this.moveThreshold = 5, this.touchStartPosition = null, this.hasExceededMoveThreshold = !1, this.canvasEvents = [
      { event: "touchstart", handler: (n) => this.onTouchStart(n) },
      { event: "touchmove", handler: (n) => this.onTouchMove(n) },
      { event: "touchend", handler: (n) => this.onTouchEnd(n) }
    ], this.setupEventListeners();
  }
  setupEventListeners() {
    const { detonatorCanvas: t } = this.context.getRenderer();
    this.canvasEvents.forEach(({ event: e, handler: n }) => {
      t.addEventListener(e, n.bind(this));
    });
  }
  onTouchStart(t) {
    t.preventDefault();
    const { state: e } = this.context;
    e.setIsDragging(!1), this.preventClickOrTap = !1, this.hasExceededMoveThreshold = !1, t.touches.length === 1 ? (this.lastTouch = t.touches[0], this.touchStartPosition = { x: this.lastTouch.clientX, y: this.lastTouch.clientY }, this.handleSingleTouch(t)) : t.touches.length === 2 && this.handleMultiTouch(t);
  }
  handleSingleTouch(t) {
    this.isSingleTouch = !0, this.touchTimer && clearTimeout(this.touchTimer), this.touchTimer = setTimeout(() => {
      this.isSingleTouch && !this.hasExceededMoveThreshold && this.handleSingleTouchStart(t.touches[0]);
    }, this.multiTouchDelay);
  }
  handleMultiTouch(t) {
    this.isSingleTouch = !1, this.touchTimer && (clearTimeout(this.touchTimer), this.touchTimer = null), this.lastPinchDistance = this.getPinchDistance(t.touches), this.lastPinchCenter = this.getPinchCenter(t.touches), this.preventClickOrTap = !0;
  }
  onTouchMove(t) {
    t.preventDefault(), this.touchTimer && (clearTimeout(this.touchTimer), this.touchTimer = null);
    const { state: e } = this.context, { lastInteractionPoint: n } = e;
    if (t.touches.length === 1 && this.isSingleTouch) {
      const s = t.touches[0];
      if (this.touchStartPosition) {
        const i = s.clientX - this.touchStartPosition.x, r = s.clientY - this.touchStartPosition.y;
        Math.sqrt(i * i + r * r) > this.moveThreshold && (this.hasExceededMoveThreshold = !0, e.setIsDragging(!0));
      }
      this.hasExceededMoveThreshold && (n || this.handleSingleTouchStart(s), this.handleInteractionMove(s));
    } else
      t.touches.length === 2 && (this.isSingleTouch = !1, this.handlePinchZoom(t.touches));
  }
  onTouchEnd(t) {
    t.preventDefault();
    const { state: e } = this.context;
    this.touchTimer && (clearTimeout(this.touchTimer), this.touchTimer = null);
    const n = (/* @__PURE__ */ new Date()).getTime(), s = this.lastTouch ? { x: this.lastTouch.clientX, y: this.lastTouch.clientY } : null;
    !this.preventClickOrTap && !this.hasExceededMoveThreshold && this.lastTouch && s ? (this.isDoubleTap(n, s) ? this.handleDoubleTap() : e.mode === u.Region ? this.regionInteraction.finish() : e.mode === u.Edit ? this.finishEdit() : this.handleClickOrTap(this.lastTouch), this.updateLastTap(n, s)) : e.mode === u.Region ? this.regionInteraction.finish() : e.mode === u.Edit && this.finishEdit(), this.resetState(), this.isSingleTouch = !1, this.lastTouch = null, this.lastPinchDistance = null, this.lastPinchCenter = null, this.touchStartPosition = null, this.hasExceededMoveThreshold = !1;
  }
  handleSingleTouchStart(t) {
    const { state: e } = this.context, n = this.context.getGrid(), s = { x: t.clientX, y: t.clientY };
    e.setLastInteractionPoint(s);
    const i = n.getScaledGridPoint(t.clientX, t.clientY), r = (/* @__PURE__ */ new Date()).getTime();
    if (this.isDoubleTap(r, s))
      this.handleDoubleTap();
    else if (this.updateLastTap(r, s), e.mode === u.Region)
      this.regionInteraction.start(i);
    else if (e.mode === u.Edit) {
      const a = n.getDetonatorAtPosition(i.x, i.y);
      e.setSelectedDetonator(a);
    }
  }
  handlePinchZoom(t) {
    if (this.lastPinchDistance === null || this.lastPinchCenter === null)
      return;
    const e = this.getPinchDistance(t), n = this.getPinchCenter(t), s = e / this.lastPinchDistance;
    this.zoom.applyZoom(n.x, n.y, s), this.lastPinchDistance = e, this.lastPinchCenter = n;
  }
  handleDoubleTap() {
    const { state: t } = this.context;
    if (t.mode === u.Region) {
      this.regionInteraction.clearRegion();
      const e = this.context.getRenderer();
      e.renderRegion(), e.renderDetonators();
    }
    this.resetDoubleTapDetection();
  }
  isDoubleTap(t, e) {
    if (this.lastTapPosition === null)
      return !1;
    const n = t - this.lastTapTime, s = e.x - this.lastTapPosition.x, i = e.y - this.lastTapPosition.y, r = s * s + i * i;
    return n < this.doubleTapDelay && r < this.doubleTapThreshold * this.doubleTapThreshold;
  }
  updateLastTap(t, e) {
    this.lastTapTime = t, this.lastTapPosition = e;
  }
  resetDoubleTapDetection() {
    this.lastTapTime = 0, this.lastTapPosition = null;
  }
  getPinchDistance(t) {
    const e = t[0].clientX - t[1].clientX, n = t[0].clientY - t[1].clientY;
    return Math.sqrt(e * e + n * n);
  }
  getPinchCenter(t) {
    return {
      x: (t[0].clientX + t[1].clientX) / 2,
      y: (t[0].clientY + t[1].clientY) / 2
    };
  }
  destroy() {
    const { detonatorCanvas: t } = this.context.getRenderer();
    for (const e of this.canvasEvents)
      t.removeEventListener(e.event, e.handler);
  }
}
class j {
  constructor(t) {
    this.context = t;
    const { minZoom: e, maxZoom: n, zoomSpeed: s, initialZoom: i } = this.context.getOptions();
    this.minZoom = e ?? 0.5, this.maxZoom = n ?? 2, this.zoomSpeed = s ?? 2e-3, this.initialZoom = i ?? 1, this.applyExternalZoom(this.initialZoom);
  }
  getZoomSpeed() {
    return this.zoomSpeed;
  }
  applyExternalZoom(t) {
    const e = this.context.getGrid(), n = this.context.getRenderer(), { width: s, height: i } = n.gridCanvas.getBoundingClientRect(), r = n.dpr, a = s / 2 * r, h = i / 2 * r, d = e.getGridPointFromCoordinates(a, h), c = Math.max(this.minZoom, Math.min(this.maxZoom, t));
    e.setScale(c);
    const g = e.gridToCanvasCoordinates(d, s * r, i * r), f = a - g.x, x = h - g.y, p = e.translation;
    e.setTranslation(p.x + f, p.y + x), n.render(), this.context.emit("zoom", c);
  }
  /**
   * Applies zoom to the grid based on the given center point and zoom factor.
   * @param centerX - The x-coordinate of the zoom center point.
   * @param centerY - The y-coordinate of the zoom center point.
   * @param zoomFactor - The factor by which to zoom (> 1 for zoom in, < 1 for zoom out).
   */
  applyZoom(t, e, n) {
    const [s, i] = [this.context.getGrid(), this.context.getRenderer()], r = i.gridCanvas.getBoundingClientRect(), a = this.calculateNewScale(n), h = this.calculateZoomCenter(t, e, r), { translation: d, scale: c } = s, g = this.calculateNewTranslation(h, d, c, a, r);
    this.applyNewScaleAndTranslation(s, a, g), i.render(), this.context.emit("zoom", a);
  }
  center() {
    const t = this.context.getGrid();
    t.setTranslation(0, 0), this.applyExternalZoom(t.scale);
  }
  calculateNewScale(t) {
    const { scale: e } = this.context.getGrid(), n = e * t;
    return Math.max(this.minZoom, Math.min(this.maxZoom, n));
  }
  calculateZoomCenter(t, e, n) {
    return {
      x: t - n.left,
      y: e - n.top
    };
  }
  calculateNewTranslation(t, e, n, s, i) {
    const r = { x: i.width / 2, y: i.height / 2 }, a = {
      x: (t.x - r.x - e.x) / n,
      y: (t.y - r.y - e.y) / n
    };
    return {
      x: t.x - (a.x * s + r.x),
      y: t.y - (a.y * s + r.y)
    };
  }
  applyNewScaleAndTranslation(t, e, n) {
    t.setScale(e), t.setTranslation(n.x, n.y);
  }
}
class U {
  constructor(t) {
    this.zoom = new j(t);
    const { type: e } = t.getOptions();
    e === "touch" ? this.touchInteraction = new A(t, this.zoom) : this.mouseInteraction = new B(t, this.zoom);
  }
  destroy() {
    var t, e;
    (t = this.mouseInteraction) == null || t.destroy(), (e = this.touchInteraction) == null || e.destroy();
  }
}
class F {
  constructor(t) {
    this.context = t;
  }
  hasDetonators() {
    return this.context.getData().length > 0;
  }
  drawCrosshair(t, e, n) {
    const { scale: s } = this.context.getGrid(), { gridSize: i, cellSize: r } = this.context.getOptions(), a = Math.min(i * r * 0.025, r * 2);
    t.beginPath(), t.moveTo(e - a / 2, n), t.lineTo(e + a / 2, n), t.moveTo(e, n - a / 2), t.lineTo(e, n + a / 2), t.strokeStyle = "red", t.lineWidth = 2 / s, t.stroke();
  }
  render(t) {
    const e = this.context.getOptions(), { gridSize: n, cellSize: s, lineColor: i, lineWidth: r, borderColor: a, borderWidth: h } = e, { scale: d } = this.context.getGrid();
    t.strokeStyle = i ?? "gray", t.lineWidth = (r ?? 0.5) / d;
    for (let c = 0; c <= n; c++)
      t.beginPath(), t.moveTo(c * s, 0), t.lineTo(c * s, n * s), t.stroke();
    for (let c = 0; c <= n; c++)
      t.beginPath(), t.moveTo(0, c * s), t.lineTo(n * s, c * s), t.stroke();
    if (t.strokeStyle = a ?? "black", t.lineWidth = (h ?? 1) / d, t.strokeRect(0, 0, n * s, n * s), !this.hasDetonators()) {
      const c = n * s / 2, g = n * s / 2;
      this.drawCrosshair(t, c, g);
    }
  }
}
class K {
  constructor(t) {
    this.radius = 0, this.fontSize = 0, this.context = t;
    const e = this.context.getOptions(), { cellSize: n } = e;
    this.radius = n * 0.5, this.fontSize = this.radius * 1.5;
  }
  render(t) {
    const e = this.context.getData(), { selectedDetonator: n, selectedDetonatorIds: s } = this.context.state;
    for (const i of e)
      (n ? n.Id === i.Id : s.length && s.includes(i.Id)) && this.drawHighlight(t, i, this.radius), t.fillStyle = i.Color, t.beginPath(), t.arc(i.X, i.Y, this.radius, 0, 2 * Math.PI), t.fill(), t.font = `${this.fontSize}px sans-serif`, t.textAlign = "center", t.textBaseline = "middle", t.strokeStyle = "black", t.lineWidth = 2, t.strokeText(i.Label, i.X, i.Y + this.fontSize * 0.05), t.fillStyle = "white", t.fillText(i.Label, i.X, i.Y + this.fontSize * 0.05);
  }
  drawHighlight(t, e, n) {
    t.save(), t.beginPath(), t.arc(e.X, e.Y, n, 0, 2 * Math.PI), t.strokeStyle = "#f18904", t.lineWidth = 4, t.stroke(), t.restore();
  }
}
class q {
  constructor(t) {
    this.context = t;
  }
  render(t) {
    const { hover: e, isDragging: n } = this.context.state;
    if (n || !(e != null && e.x) || !(e != null && e.y))
      return;
    const s = this.context.getOptions(), { cellSize: i } = s;
    t.fillStyle = "rgba(255, 255, 255, 0.3)", t.beginPath(), t.arc(e.x, e.y, i / 2, 0, 2 * Math.PI), t.fill();
  }
}
class J {
  constructor(t) {
    this.context = t;
  }
  render(t) {
    const { region: e } = this.context.state;
    if (!(e != null && e.start) || !e.end)
      return;
    const { scale: n } = this.context.getGrid(), s = this.getRegionBounds();
    if (!s)
      return;
    const i = s.endX - s.startX, r = s.endY - s.startY;
    t.save(), t.strokeStyle = "#f18904", t.fillStyle = "rgba(241, 137, 4, 0.3)", t.lineWidth = 2 / n, t.setLineDash([5 / n, 5 / n]), t.fillRect(s.startX, s.startY, i, r), t.strokeRect(s.startX, s.startY, i, r), t.restore();
  }
  getRegionBounds() {
    const { region: t } = this.context.state;
    return !t || !t.start || !t.end ? null : {
      startX: Math.min(t.start.x, t.end.x),
      startY: Math.min(t.start.y, t.end.y),
      endX: Math.max(t.start.x, t.end.x),
      endY: Math.max(t.start.y, t.end.y)
    };
  }
}
class $ {
  constructor(t, e) {
    this.dpr = window.devicePixelRatio || 1, this.context = e, this.element = t, this.gridCanvas = this.createCanvas(), t.appendChild(this.gridCanvas), this.regionCanvas = this.createCanvas(), t.appendChild(this.regionCanvas), this.detonatorCanvas = this.createCanvas(), t.appendChild(this.detonatorCanvas), this.gridRenderer = new F(e), this.detonatorRenderer = new K(e), this.hoverRenderer = new q(e), this.regionRenderer = new J(e);
  }
  createCanvas() {
    const t = document.createElement("canvas");
    return t.style.position = "absolute", t.style.inset = "0", t;
  }
  updateCanvasSize(t, e, n) {
    t.width = e * this.dpr, t.height = n * this.dpr, t.style.width = `${e}px`, t.style.height = `${n}px`;
  }
  getCanvasContext(t) {
    const e = t.getContext("2d");
    if (!e)
      throw new Error("Unable to get 2d context");
    return e;
  }
  render() {
    const { clientWidth: t, clientHeight: e } = this.element;
    this.updateCanvasSize(this.gridCanvas, t, e), this.updateCanvasSize(this.regionCanvas, t, e), this.updateCanvasSize(this.detonatorCanvas, t, e), this.renderGrid(), this.renderDetonators(), this.renderRegion();
  }
  renderGrid() {
    this.renderOnCanvas(this.gridCanvas, [this.gridRenderer]);
  }
  renderDetonators() {
    this.renderOnCanvas(this.detonatorCanvas, [this.detonatorRenderer, this.hoverRenderer]);
  }
  renderRegion() {
    this.renderOnCanvas(this.regionCanvas, [this.regionRenderer]);
  }
  renderOnCanvas(t, e) {
    const n = this.getCanvasContext(t), { width: s, height: i } = t;
    n.clearRect(0, 0, s, i), n.save(), this.applyTransformations(n);
    for (const r of e)
      r.render(n);
    n.restore();
  }
  updateCursorStyle(t, e) {
    t.style.cursor = e;
  }
  renderHover(t) {
    this.context.state.setHover(t), this.renderDetonators();
  }
  clearHover() {
    this.context.state.setHover(null), this.renderDetonators();
  }
  applyTransformations(t) {
    const e = this.context.getOptions(), { gridSize: n, cellSize: s } = e, i = this.context.getGrid(), { scale: r, translation: a } = i, { width: h, height: d } = t.canvas;
    t.translate(h / 2 + a.x * this.dpr, d / 2 + a.y * this.dpr), t.scale(r * this.dpr, r * this.dpr), t.translate(-n * s / 2, -n * s / 2);
  }
}
class Q {
  constructor() {
    this.state = {
      mode: u.Normal,
      selectedDetonator: null,
      selectedDetonators: [],
      region: null,
      dragOffset: { x: 0, y: 0 },
      isDragging: !1,
      lastInteractionPoint: null,
      hover: null,
      isPanning: !1
    };
  }
  get selectedDetonatorIds() {
    return this.state.selectedDetonators;
  }
  get mode() {
    return this.state.mode;
  }
  get selectedDetonator() {
    return this.state.selectedDetonator;
  }
  get region() {
    return this.state.region;
  }
  get dragOffset() {
    return this.state.dragOffset;
  }
  get isDragging() {
    return this.state.isDragging;
  }
  get lastInteractionPoint() {
    return this.state.lastInteractionPoint;
  }
  get hover() {
    return this.state.hover;
  }
  get isPanning() {
    return this.state.isPanning;
  }
  setSelectedDetonators(t) {
    this.state.selectedDetonators = t.map((e) => e.Id);
  }
  clearSelectedDetonators() {
    this.state.selectedDetonators = [];
  }
  setMode(t) {
    this.state.mode = t;
  }
  setSelectedDetonator(t) {
    this.state.selectedDetonator = t;
  }
  setRegion(t) {
    this.state.region = t;
  }
  setIsDragging(t) {
    this.state.isDragging = t;
  }
  setIsPanning(t) {
    this.state.isPanning = t;
  }
  setLastInteractionPoint(t) {
    this.state.lastInteractionPoint = t;
  }
  setDragOffset(t) {
    this.state.dragOffset = t;
  }
  setHover(t) {
    this.state.hover = t;
  }
  reset() {
    this.setSelectedDetonator(null), this.setRegion(null), this.setIsDragging(!1), this.setIsPanning(!1), this.setLastInteractionPoint(null), this.setDragOffset({ x: 0, y: 0 }), this.setHover(null), this.clearSelectedDetonators();
  }
}
class V extends E.EventEmitter {
  constructor(t = []) {
    super(), this.history = [], this.currentHistoryIndex = -1, this.maxHistorySize = 25, this.hasInitializedHistory = !1, this.data = [], this.data = t;
  }
  getData() {
    return this.data;
  }
  updateData(t) {
    this.data = t, this.hasInitializedHistory || this.setInitialHistory();
  }
  safeClone(t) {
    return JSON.parse(JSON.stringify(t));
  }
  setInitialHistory() {
    this.history = [{ data: this.safeClone(this.data) }], this.currentHistoryIndex = 0, this.hasInitializedHistory = !0;
  }
  addHistory() {
    if (!this.hasInitializedHistory) {
      this.setInitialHistory();
      return;
    }
    if (this.currentHistoryIndex < this.history.length - 1 && (this.history = this.history.slice(0, this.currentHistoryIndex + 1)), this.history.push({
      data: this.safeClone(this.data)
    }), this.currentHistoryIndex++, this.history.length > this.maxHistorySize) {
      const t = this.history.length - this.maxHistorySize;
      this.history = this.history.slice(t), this.currentHistoryIndex = Math.max(0, this.currentHistoryIndex - t);
    }
  }
  undo() {
    if (!this.canUndo())
      return !1;
    this.currentHistoryIndex--;
    const t = this.safeClone(this.data);
    this.data = this.safeClone(this.history[this.currentHistoryIndex].data);
    const e = this.getStateChanges(t, this.data);
    return this.emit("undo", e.updated, e.removed), !0;
  }
  redo() {
    if (!this.canRedo())
      return !1;
    this.currentHistoryIndex++;
    const t = this.safeClone(this.data);
    this.data = this.safeClone(this.history[this.currentHistoryIndex].data);
    const e = this.getStateChanges(t, this.data);
    return this.emit("redo", e.updated, e.removed), !0;
  }
  canUndo() {
    return this.hasInitializedHistory && this.currentHistoryIndex > 0;
  }
  canRedo() {
    return this.hasInitializedHistory && this.currentHistoryIndex < this.history.length - 1;
  }
  clearHistory() {
    this.history = [{ data: this.safeClone(this.data) }], this.currentHistoryIndex = 0, this.hasInitializedHistory = !0;
  }
  getHistorySize() {
    return this.history.length;
  }
  getCurrentIndex() {
    return this.currentHistoryIndex;
  }
  getStateChanges(t, e) {
    const n = new Set(t.map((a) => a.Id)), s = new Set(e.map((a) => a.Id)), i = t.filter((a) => !s.has(a.Id));
    return { updated: e.filter((a) => {
      if (!n.has(a.Id))
        return !0;
      const h = t.find((d) => d.Id === a.Id);
      return h ? h.X !== a.X || h.Y !== a.Y || h.MagazineItemId !== a.MagazineItemId : !0;
    }), removed: i };
  }
}
class tt extends E.EventEmitter {
  constructor(t, e, n) {
    super(), this.data = e, this.options = this.getDefaultOptions(n), this.state = new Q(), this.historyManager = new V(e), this.historyManager.on("undo", (s, i) => this.emit("undo", s, i)), this.historyManager.on("redo", (s, i) => this.emit("redo", s, i)), this.renderer = new $(t, this), this.grid = new N(this), this.interactionManager = new U(this), this.resizeObserver = new ResizeObserver(this.onResize.bind(this)), this.resizeObserver.observe(t), this.renderer.render();
  }
  getDefaultOptions(t) {
    return {
      type: t.type ?? "touch",
      gridSize: t.gridSize ?? 32,
      cellSize: t.cellSize ?? 32,
      lineColor: t.lineColor ?? "#cccccc",
      lineWidth: t.lineWidth ?? 0.5,
      borderColor: t.borderColor ?? "transparent",
      borderWidth: t.borderWidth ?? 1,
      initialZoom: t.initialZoom ?? 1,
      minZoom: t.minZoom ?? 0.5,
      maxZoom: t.maxZoom ?? 2,
      zoomSpeed: t.zoomSpeed ?? 5e-3
    };
  }
  getData() {
    return this.data;
  }
  updateData(t) {
    this.data = t, this.historyManager.updateData(t), this.renderer.render();
  }
  getGrid() {
    return this.grid;
  }
  getRenderer() {
    return this.renderer;
  }
  getOptions() {
    return this.options;
  }
  setMode(t) {
    this.state.setMode(t), this.state.reset(), this.renderer.render();
  }
  setZoom(t) {
    const { zoom: e } = this.interactionManager;
    e.applyExternalZoom(t);
  }
  onResize() {
    var t;
    this.renderer.render(), (t = this.interactionManager.zoom) == null || t.center();
  }
  destroy() {
    this.resizeObserver.disconnect(), this.interactionManager.destroy();
  }
  export() {
    return JSON.stringify(this.data);
  }
  // History methods
  undo() {
    this.historyManager.undo() && (this.data = this.historyManager.getData(), this.state.reset(), this.renderer.render());
  }
  redo() {
    this.historyManager.redo() && (this.data = this.historyManager.getData(), this.state.reset(), this.renderer.render());
  }
  addHistory() {
    this.historyManager.addHistory();
  }
  canUndo() {
    return this.historyManager.canUndo();
  }
  canRedo() {
    return this.historyManager.canRedo();
  }
}
export {
  tt as FaceSheetGrid,
  u as InteractionMode
};
