<template>
    <schema-form ref="form" v-bind="$attrs" v-on="$listeners">
        <template #field-isHazardRequired="{ field, item }">
            <form-checkbox v-model="item.isHazardRequired" :field="field" :disabled="isHazardRequiredDisabled(item)" />
        </template>

        <template #field-isNotApplicable="{ field, item }">
            <form-checkbox v-model="item.isNotApplicable" :field="field" :disabled="isNotApplicableDisabled(item)" />
        </template>

        <template #field-inspectionType="{ field, item }">
            <form-enum
                v-model="item.inspectionType"
                :field="field"
                @change="(newVal) => onInspectionTypeChange(newVal, item)"
            />
        </template>

        <template #field-instructions="{ field, item }">
            <form-text-area v-show="item.isHazardRequired" v-model="item.instructions" :field="field" />
        </template>

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </schema-form>
</template>

<script>
    import FormCheckbox from "../formFields/FormCheckbox.vue";
    import FormEnum from "../formFields/FormEnum.vue";
    import FormTextArea from "../formFields/FormTextArea.vue";
    import { getEnum } from "@/features/schemas/services/schemaProvider";

    export default {
        components: { FormCheckbox, FormEnum, FormTextArea },
        data() {
            return {
                inspectionTypeEnum: getEnum("inspectionType"),
            };
        },

        methods: {
            async save() {
                return await this.$refs.form.save();
            },
            isNotApplicableDisabled(item) {
                return (
                    item.inspectionType === Object.keys(this.inspectionTypeEnum)[1] ||
                    item.inspectionType === Object.keys(this.inspectionTypeEnum)[2]
                );
            },
            isHazardRequiredDisabled(item) {
                if (
                    item.inspectionType === Object.keys(this.inspectionTypeEnum)[1] ||
                    item.inspectionType === Object.keys(this.inspectionTypeEnum)[2]
                ) {
                    return true;
                }
                return false;
            },

            onInspectionTypeChange(inspectionType, item) {
                if (
                    inspectionType === Object.keys(this.inspectionTypeEnum)[1] ||
                    inspectionType === Object.keys(this.inspectionTypeEnum)[2]
                ) {
                    item.isNotApplicable = 0;
                    item.isHazardRequired = false;
                }
            },
        },
    };
</script>
