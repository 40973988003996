<template>
    <v-text-field v-bind="$attrs" :outlined="outlined" :dense="dense" v-on="$listeners">
        <template #prepend>
            <slot name="prepend"></slot>
        </template>
    </v-text-field>
</template>

<script>
    export default {
        props: {
            dense: {
                type: Boolean,
                default: true,
            },
            outlined: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>
