<template>
    <schema-data-table ref="table" v-bind="$attrs" v-on="$listeners">
        <template #item.iconImage="{ item }">
            <exm-icon :icon="item" height="40px" width="40px" />
        </template>
    </schema-data-table>
</template>

<script>
    import ExmIcon from "@/components/ExmIcon";

    export default {
        components: {
            ExmIcon,
        },
        methods: {
            async refresh() {
                await this.$refs.table.refresh();
            },
        },
    };
</script>
