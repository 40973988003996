import api from "@/features/schemas/services/schemaApi";
import { canAdd, canEdit, canDelete } from "@/features/schemas/services/schemaApi";
import { getComponent } from "@/features/schemas/formFields";
import schemaMixin from "./schemaMixin";
import fixedValuesMixin from "./fixedValuesMixin";
import defaultValuesMixin from "./defaultValuesMixin";
import { hasProperty } from "@/services/objectUtility";
import { mapState } from "vuex";
import { handleError } from "@/services/errorUtility";
import { getCustomFields } from "../services/customFieldProvider";
import { toCamelCase } from "@/services/stringUtility";

export default {
    mixins: [schemaMixin, fixedValuesMixin, defaultValuesMixin],
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        disableDelete: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            isValid: false,
            isSaving: false,
            item: {},
            customFieldItems: [],
        };
    },

    computed: {
        ...mapState({
            plodtrackEnabled: (state) => state.settings.plodtrackEnabled,
        }),
        fields() {
            return Object.values(this.entity.commandFields).filter(
                (e) =>
                    e.key !== "id" &&
                    !this.isFixedValue(e.key) &&
                    !this.isDefaultValue(e.key) &&
                    // Hide fields that have the "hidden" property
                    !hasProperty(e, "hidden") &&
                    // Hide fields that are related to Plodtrack if not enabled
                    this.showPlodtrackField(e)
            );
        },
        customFields() {
            return this.customFieldItems.map((x) => ({ id: x.id, name: x.name, value: toCamelCase(x.name) }));
        },
        form() {
            return this.$refs.form;
        },
        isEdit() {
            return !!this.value?.id;
        },
        canSave() {
            return this.isEdit ? canEdit(this.entityKey) : canAdd(this.entityKey);
        },
        canCancel() {
            return this.$listeners?.cancel;
        },
        canDelete() {
            return !this.disableDelete && canDelete(this.entityKey) && this.isEdit;
        },
    },

    watch: {
        value: {
            immediate: true,
            async handler(value) {
                this.customFieldItems = await getCustomFields(this.entityKey);
                if (value) {
                    this.item = JSON.parse(JSON.stringify(value));
                } else {
                    this.item = { customFields: {} };
                }
            },
        },
    },

    methods: {
        getComponent,
        async save() {
            if (this.isSaving || !this.form.validate()) {
                return false;
            }

            this.isSaving = true;

            try {
                const model = { ...this.item, ...this.fixedValues, ...this.defaultValues };
                this.item = await api.save(this.entityKey, model, true);
                this.$emit("input", this.item);
            } catch (error) {
                handleError(error);
            } finally {
                this.isSaving = false;
            }

            return true;
        },

        async deleteItem(showConfirm = true) {
            if (this.isSaving) {
                return;
            }

            if (showConfirm) {
                if (!confirm("Are you sure you want to delete this item?")) {
                    return;
                }
            }

            this.isSaving = true;

            try {
                await api.delete(this.entityKey, this.value?.id);
                this.$emit("input", null);
            } finally {
                this.isSaving = false;
            }
        },
        showPlodtrackField(field) {
            if (hasProperty(field, "subtype") && field.subtype.name === "Plodtrack") {
                return this.plodtrackEnabled;
            }

            return true;
        },
    },
};
