<template>
    <v-card class="d-flex flex-column overflow-hidden">
        <v-tabs v-model="activeTab" style="flex: 0 1 auto">
            <v-tab class="ml-4">Detonator Grid</v-tab>
            <v-tab>Items</v-tab>
        </v-tabs>

        <charge-standard-grid-wrapper
            v-show="activeTab == 0"
            ref="gridWrapper"
            class="flex-grow-1"
            :standard-id="standardId"
        />

        <schema-table
            v-if="activeTab == 1"
            class="flex-grow-1 pa-4"
            :entity-key="entityKey"
            v-bind="$attrs"
            v-on="$listeners"
            :default-values="defaultValues"
            :fixed-values="{ coordinates: null, chargeStandardId: standardId }"
        />
    </v-card>
</template>

<script>
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";

    // Components
    import ChargeStandardGridWrapper from "@/features/chargeStandards/components/ChargeStandardGridWrapper.vue";

    export default {
        mixins: [schemaMixin],
        components: {
            ChargeStandardGridWrapper,
        },
        data() {
            return {
                activeTab: 0,
                magazineItems: [],
            };
        },
        props: {
            parentEntity: {
                type: Object,
            },
        },
        watch: {
            async activeTab(newTab) {
                if (newTab === 0) {
                    const gridWrapper = this.$refs.gridWrapper;
                    if (gridWrapper) {
                        await gridWrapper.refreshItems();
                    }
                }
            },
        },
        methods: {
            async onSave() {
                await this.$refs.schemaTable.onItemSaved();
            },
        },
        computed: {
            standardId() {
                return this.parentEntity?.item?.id;
            },
            defaultValues() {
                if (this.parentEntity == null) {
                    return null;
                }
                // Hides and defaults the coordinates
                return {
                    chargeStandardId: this.parentEntity.item.id,
                    coordinates: null,
                };
            },
        },
    };
</script>
