<template>
    <v-row no-gutters>
        <v-col cols="6">
            <icon-enum v-model="value.iconImage" :field="iconImageField" />

            <form-color-picker v-model="value.primaryColor" :field="primaryColorField" />
            <form-color-picker v-model="value.secondaryColor" :field="secondaryColorField" />
            <form-color-picker v-model="value.backgroundColor" :field="backgroundColorField" />
        </v-col>
        <v-col cols="6" v-if="value.iconImage" align="center" align-self="center">
            <exm-icon v-if="value" :icon="value" width="200px" height="200px" />
        </v-col>
    </v-row>
</template>

<script>
    import IconEnum from "@/features/schemas/formFields/IconEnum.vue";
    import FormColorPicker from "@/features/schemas/formFields/FormColorPicker.vue";
    import ExmIcon from "@/components/ExmIcon.vue";

    export default {
        components: { IconEnum, FormColorPicker, ExmIcon },
        props: { value: Object },
        data() {
            return {
                iconImageField: {
                    required: {},
                    type: "iconImage",
                    title: "Icon Image",
                },
                primaryColorField: {
                    type: "string",
                    title: "Primary Colour",
                },
                secondaryColorField: {
                    type: "string",
                    title: "Secondary Colour",
                },
                backgroundColorField: {
                    type: "string",
                    title: "Background Colour",
                },
            };
        },
    };
</script>
