<template>
    <div ref="grid" class="grid"></div>
</template>

<script>
    import { FaceSheetGrid, InteractionMode } from "face-sheet-grid";

    export default {
        mixins: [],
        components: {},
        data() {
            return {
                faceSheetGrid: null,
                options: {
                    type: "mouse",
                    initialZoom: 0.62,
                    minZoom: 0.5,
                    maxZoom: 2,
                    zoomSpeed: 0.002,
                },
                interactionModeEnum: InteractionMode,
                canUndo: false,
                canRedo: false,
            };
        },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            selectedDetonator: {
                type: Object,
                default: () => {},
            },
            mode: {
                type: Number,
            },
        },
        emits: ["add", "edit", "batchUpdate", "delete", "historyStateChanged"],
        mounted() {
            // Force the device pixel ratio to 1 to reduce blurry canvas.
            window.devicePixelRatio = 1;

            this.initializeGrid();
        },
        watch: {
            value: {
                handler(newValue) {
                    if (this.faceSheetGrid) {
                        this.faceSheetGrid.updateData(newValue);
                    }
                },
                deep: true,
                immediate: true,
            },
            mode(value) {
                this.faceSheetGrid.setMode(value);
            },
        },
        methods: {
            initializeGrid() {
                if (!this.$refs.grid) {
                    throw new Error("Face sheet grid Error");
                }

                this.faceSheetGrid = new FaceSheetGrid(this.$refs.grid, this.value, this.options);
                this.faceSheetGrid.setMode(this.mode);

                this.faceSheetGrid.on("add", this.handleAdd);
                this.faceSheetGrid.on("edit", this.handleEdit);
                this.faceSheetGrid.on("batchEdit", (updated, removed) =>
                    this.handleBatchUpdate("batchEdit", updated, removed)
                );
                this.faceSheetGrid.on("delete", this.handleDelete);
                this.faceSheetGrid.on("undo", (updated, removed) => this.handleBatchUpdate("undo", updated, removed));
                this.faceSheetGrid.on("redo", (updated, removed) => this.handleBatchUpdate("redo", updated, removed));
            },
            handleAdd(point) {
                var duplicate = this.value.find((det) => det.X == point.x && det.Y == point.y);
                if (duplicate) return;

                if (!this.selectedDetonator?.Label) return;
                this.$emit("add", point);
            },
            handleEdit(detonator) {
                var duplicate = this.value.find((det) => det.X == detonator.x && det.Y == detonator.y);
                if (duplicate) return;

                this.$emit("edit", detonator);
            },
            handleBatchUpdate(type, updated, removed) {
                this.$emit("batchUpdate", type, updated, removed);
                this.updateUndoRedoState();
            },
            handleDelete(detonator) {
                this.$emit("delete", detonator);
            },
            updateUndoRedoState() {
                this.$emit("historyStateChanged", {
                    canUndo: this.faceSheetGrid.canUndo(),
                    canRedo: this.faceSheetGrid.canRedo(),
                });
            },

            undo() {
                this.faceSheetGrid?.undo();
            },

            redo() {
                this.faceSheetGrid?.redo();
            },

            addHistory() {
                this.faceSheetGrid?.addHistory();
                this.updateUndoRedoState();
            },
        },
        beforeDestroy() {
            if (this.faceSheetGrid) {
                this.faceSheetGrid.destroy();
            }
        },
    };
</script>

<style scoped>
    .grid {
        height: 640px;
        width: 640px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 8px;
    }
</style>
