<template>
    <v-alert dense prominent :type="currentMode.color" :icon="currentMode.icon" class="my-0">
        <div class="text-h5" style="line-height: 24px">{{ currentMode.title }}</div>
        <div class="text-subtitle-1" style="line-height: 16px">{{ currentMode.subtitle }}</div>
    </v-alert>
</template>

<script>
    import { InteractionMode } from "face-sheet-grid";

    export default {
        props: {
            mode: {
                type: Number,
            },
        },
        data() {
            return {
                modes: {
                    [InteractionMode.Normal]: {
                        title: "Add Mode", // TODO: Normal or Add Mode
                        subtitle: "Select and Click to place an item",
                        color: "success",
                        icon: "mdi-information",
                    },
                    [InteractionMode.Edit]: {
                        title: "Editing",
                        subtitle: "Drag to move a detonator",
                        color: "info",
                        icon: "mdi-pencil",
                    },
                    [InteractionMode.Delete]: {
                        title: "Deleting",
                        subtitle: "Tap to delete a detonator",
                        color: "error",
                        icon: "mdi-delete",
                    },
                    [InteractionMode.Region]: {
                        title: "Selecting",
                        subtitle: "Drag to select multiple dets / Double tap to clear",
                        color: "warning",
                        icon: "mdi-select",
                    },
                },
            };
        },
        computed: {
            currentMode() {
                return this.modes[this.mode] || this.modes[InteractionMode.Normal];
            },
        },
    };
</script>
