<template>
    <v-row no-gutters>
        <v-col cols="2">
            <app-date-field v-model="localFrom" class="min-input" label="From Date" :max="max" hide-details />
        </v-col>

        <v-col class="ml-2" cols="2">
            <app-date-field v-model="localTo" class="min-input" label="To Date" :max="max" hide-details />
        </v-col>

        <v-col align="end">
            <v-btn class="mr-2" outlined :height="40" @click="setDateYesterday()">Yesterday</v-btn>
            <v-btn outlined :height="40" @click="setDateToday()">Today</v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import { today, toDateIso } from "@/services/dateUtility";
    import localValueMixin from "@/mixins/localValueMixin";

    function todayIso() {
        return toDateIso(today());
    }

    export default {
        mixins: [localValueMixin],
        props: {
            from: {
                type: String,
                default: null,
            },
            to: {
                type: String,
                default: null,
            },
        },

        methods: {
            setDateYesterday() {
                const date = toDateIso(today().minus({ days: 1 }));
                this.updateDateValues(date);
            },
            setDateToday() {
                const date = toDateIso(today());
                this.updateDateValues(date);
            },
            updateDateValues(date) {
                this.localFrom = date;
                this.localTo = date;
            },
        },

        computed: {
            max() {
                return todayIso().substring(0, 10);
            },
            localFrom: {
                get() {
                    return this.from;
                },
                set(value) {
                    this.$emit("update:from", value);
                },
            },
            localTo: {
                get() {
                    return this.to;
                },
                set(value) {
                    this.$emit("update:to", value);
                },
            },
        },
    };
</script>
