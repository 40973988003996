<template>
    <div>
        <v-row class="pb-2" no-gutters align="end">
            <exm-icon v-if="itemIcon" style="height: 50px; width: 50px" :icon="itemIcon" />
            <div class="text-h5 mx-2">{{ itemName }}</div>
            <div class="text-subtitle-1 mx-2">{{ itemDescription }}</div>
        </v-row>

        <v-divider />
        <div style="height: 500px; overflow: auto">
            <v-data-table
                :headers="computedHeaders"
                :items="items"
                hide-default-footer
                :footer-props="{ 'items-per-page-options': [-1] }"
                :sort-by.sync="localSortBy"
                :sort-desc.sync="localSortDesc"
            >
                <template #item.icon="{ item }">
                    <div class="d-flex align-center justify-center">
                        <exm-icon
                            style="height: 36px; width: 36px"
                            :icon="{
                                iconImage: 'detClip',
                                primaryColor: null,
                                secondaryColor: null,
                                backgroundColor: item.icon != null ? item.icon.backgroundColor : item.backgroundColor,
                            }"
                        />
                    </div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import ExmIcon from "@/components/ExmIcon.vue";

    export default {
        components: { ExmIcon },
        props: {
            itemName: String,
            itemDescription: String,
            itemIcon: Object,
            showIcon: Boolean,
            items: Array,
            sortBy: String,
            sortDesc: Boolean,
        },
        data() {
            return {
                localSortBy: "priority",
                localSortDesc: false,
                headers: [
                    {
                        text: "Icon",
                        value: "icon",
                        sortable: false,
                        align: "center",
                        visible: () => this.showIcon,
                    },
                    {
                        text: "Name",
                        value: "name",
                    },
                    {
                        text: "Description",
                        value: "description",
                    },
                    {
                        text: "Quantity Per Box",
                        value: "quantityPerBox",
                    },
                    {
                        text: "Quantity Per Pallet",
                        value: "quantityPerPallet",
                    },
                    {
                        text: "Weight Per Item",
                        value: "weightPerItem",
                    },
                    {
                        text: "Priority",
                        value: "priority",
                    },
                ],
            };
        },

        computed: {
            computedHeaders() {
                return this.headers.filter((x) => x.visible === undefined || x.visible());
            },
        },
        watch: {
            sortBy: {
                immediate: true,
                handler() {
                    this.localSortBy = this.sortBy;
                },
            },
            sortDesc: {
                immediate: true,
                handler() {
                    this.localSortDesc = this.sortDesc;
                },
            },
        },
    };
</script>
