import axios from "axios";
import webLayoutEnum from "@/enums/webLayoutEnum";

export const settings = {
    namespaced: true,
    state: {
        // Add new setting below:
        webLayout: 0,
        timeOffset: "08:00:00",
        developmentDrillApprovalMode: 0,
        lockDevelopmentDrillStandards: false,
        enableDevelopmentDrillFired: false,
        lockIndividualModules: false,
        individualEdit: false,
        moduleFilter: false,
        enableNetworkDiscovery: false,
        serverUrl: null,
        serverPort: null,
        serverUUID: null,
        enableShiftAllocation: false,
        enableEquipmentTimelineReview: false,
        enableFaceSamplingIntegration: false,
        faceSamplingIntegrationMode: 0,
        maxFileSizeMB: 4,
        enableWeightDistance: false,
        enableExplosivesManagement: false,
        defaultShiftReviewToPreviousShift: true,
        allowCustomPlans: true,
        simpleHaulMode: false,
        enableOPHaulv2: false,
        enableRelativeLevel: true,
        allowHaulWeight: false,
        canEditHaulWeight: false,
        loaderIdRequired: false,
        allowLoadWeight: false,
        canEditLoadWeight: false,
        defaultLocationSort: 0,
        deviceApproval: true,
        timeUsage: false,
        faceSampling: false,
        faceSamplingVideoInterval: 30,
        allowMultipleSites: false,
        serverSiteCode: null,
        serverSiteId: null,
        hideModuleActivities: false,
        disableAutoPlacement: true,
        allowUsageTracking: false,
        shiftChangeTime: "01:00:00",
        allowFileDownload: false,
        exmSettings: null,
        enforceNoSignals: false,
        enableChargePlanTransfer: false,
        enableMagazineKeys: false,
        enableSignatures: false,
        culture: "en-AU",
        timeZone: "Australia/Perth",
        timelineInterval: 30,
        shiftSheetLayout: null,
        productCapacity: false,
        useHaulVolume: false,
        useLoadVolume: false,
        unit: null,
        enableShotcreteBatchNumber: false,
        enableDevelopmentDrillPlanNumber: false,
        plodtrackEnabled: false,
        enableWorkplaceInspections: false,
    },
    actions: {
        async initialize({ commit, rootState }) {
            try {
                if (!rootState.authentication.user) {
                    return;
                }

                const { data: settings } = await axios.get("/api/Settings");
                const settingKeys = Object.keys(settings);

                for (const key of settingKeys) {
                    commit("setSetting", {
                        key: key,
                        value: settings[key],
                    });
                }

                if (settings.defaultLengthUnit) {
                    const { data: unit } = await axios.get("/api/Units/" + settings.defaultLengthUnit);
                    commit("setSetting", {
                        key: "unit",
                        value: unit,
                    });
                }

                const { data } = await axios.get("/api/Plodtrack/IsPlodtrackEnabled");
                settings.plodtrackEnabled = data ?? false;
            } catch {
                /* let the interceptor handle any redirection to the login page. */
            }
        },
    },
    getters: {
        defaultUnit(state) {
            return state.unit ? state.unit.name : "Metres";
        },
        defaultUnitShort(state) {
            if (state.unit?.format) {
                return state.unit.format.replace("0", "").trim();
            }

            return "m";
        },
        showLiveMine(state) {
            return state.webLayout === webLayoutEnum.LiveMine || state.webLayout === webLayoutEnum.LiveMineAndEXM;
        },
        showEXM(state) {
            return state.webLayout === webLayoutEnum.EXM || state.webLayout === webLayoutEnum.LiveMineAndEXM;
        },
    },
    mutations: {
        setSetting(state, { key, value }) {
            state[key] = value;
        },
    },
};
