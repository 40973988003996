<template>
    <div class="state-control-container">
        <v-row>
            <app-button
                color="white"
                class="state-button"
                outlined
                :disabled="!canUndo"
                @mousedown.stop.prevent
                @mouseup.stop.prevent
                @click.stop.prevent="$emit('undo')"
            >
                <v-icon>mdi-undo</v-icon>
            </app-button>
            <v-spacer />
            <app-button
                color="white"
                class="state-button"
                outlined
                :disabled="!canRedo"
                @mousedown.stop.prevent
                @mouseup.stop.prevent
                @click.stop.prevent="$emit('redo')"
            >
                <v-icon>mdi-redo</v-icon>
            </app-button>
        </v-row>
    </div>
</template>

<script>
    export default {
        props: {
            canUndo: {
                type: Boolean,
                required: true,
            },
            canRedo: {
                type: Boolean,
                required: true,
            },
        },
        emits: ["undo", "redo"],
    };
</script>

<style lang="scss" scoped>
    .state-control-container {
        width: 100%;
        padding: 8px 16px;
        position: absolute;
        margin: 8px 0px;
        bottom: 0;
        z-index: 10;
    }

    .state-button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 48px !important;
        max-height: 48px !important;
        background: rgba(0, 58, 73, 0.1) !important;
    }
</style>
