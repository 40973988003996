<template>
    <v-menu v-model="menu" offset-y offset-x left :close-on-content-click="false">
        <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
        </template>

        <v-form ref="form" v-model="isValid" @submit.prevent="onSave()">
            <v-card>
                <v-card-text>
                    <app-text-field v-model="columnName" label="Add Column" required hide-details />
                </v-card-text>
                <v-card-actions>
                    <app-button color="primary" text @click="reset()">Cancel</app-button>
                    <v-spacer />
                    <app-button type="submit" text>Save</app-button>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-menu>
</template>

<script>
    import { add } from "@/features/schemas/services/schemaApi";
    import { getEntityByte } from "@/features/schemas/services/schemaProvider";

    export default {
        mixins: [],
        props: {
            entityKey: {
                type: String,
                required: true,
            },
        },
        emits: ["save"],
        data() {
            return {
                isValid: false,
                menu: false,
                columnName: null,
                isSaving: false,
            };
        },
        computed: {
            form() {
                return this.$refs.form;
            },
        },
        methods: {
            async onSave() {
                try {
                    if (this.isSaving || !this.form.validate()) {
                        return;
                    }

                    this.isSaving = true;
                    const entityByte = getEntityByte(this.entityKey);
                    const item = await add("customField", { entity: entityByte, name: this.columnName });
                    this.$emit("save", item);
                } finally {
                    this.reset();
                }
            },
            reset() {
                this.isSaving = false;
                this.columnName = null;
                this.menu = false;
            },
        },
    };
</script>
