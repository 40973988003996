<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title class="pb-0">Comments</v-card-title>
            <v-card-text>
                <v-textarea v-model="valueLocal" required></v-textarea>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn color="secondary" text @click="(dialog = false), (valueLocal = value)">Close</v-btn>
                <v-btn color="primary" text @click="onSave">Save</v-btn>
            </v-card-actions>
        </v-card>

        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" :on="on" :open="open">
                <!-- Placeholder - not used -->
                <v-btn dark v-bind="attrs" v-on="on">Click Me</v-btn>
            </slot>
        </template>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                default: () => "",
            },
        },
        data() {
            return {
                dialog: false,
                valueLocal: this.value,
            };
        },
        watch: {
            value(newValue) {
                this.valueLocal = newValue;
            },
        },
        methods: {
            open() {
                this.dialog = true;
            },
            onSave() {
                this.dialog = false;
                this.$emit("input", this.valueLocal);
            },
        },
    };
</script>

<style scoped>
    :deep(textarea) {
        resize: none !important;
    }
</style>
