<template>
    <div v-if="icon != null" class="d-flex fill-height align-center no-overflow-ellipsis">
        <exm-icon style="height: 22px; width: 22px" :icon="icon" />
    </div>
</template>

<script>
    import { camelToPascalCase } from "@/services/stringUtility";
    import ExmIcon from "@/components/ExmIcon";

    export default {
        types: ["icon"],
        components: {
            ExmIcon,
        },
        props: {
            value: {
                type: [String, Object],
                default: () => null,
            },
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        methods: {
            formatIconName(iconName) {
                if (!iconName) return "";
                return camelToPascalCase(iconName);
            },
        },
        computed: {
            icon() {
                return this.value;
            },
        },
    };
</script>
