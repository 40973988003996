import { toCamelCase } from "@/services/stringUtility";
import { getCustomFields } from "../services/customFieldProvider";
import { getCommandField } from "../services/schemaProvider";
import { canAdd } from "../services/schemaApi";

export default {
    mixins: [],
    props: {},

    data() {
        return {
            customFields: [],
        };
    },

    async mounted() {
        this.customFields = await getCustomFields(this.entityKey);
    },

    computed: {
        customFieldHeaders() {
            return this.customFields.map((x) => ({
                id: x.id,
                text: x.name,
                value: toCamelCase(x.name),
                sortable: false,
            }));
        },
        customFieldKeys() {
            return this.customFields.map((x) => toCamelCase(x.name)) ?? [];
        },
        canAddCustomField() {
            return getCommandField(this.entityKey, "customFields") && canAdd("customField");
        },
    },

    watch: {},

    methods: {
        getCustomFieldValue(item, fieldName) {
            if (item.customFields == null) return null;

            return item.customFields[fieldName];
        },
        addCustomField(customField) {
            this.customFields = [...this.customFields, customField];
        },
        updateCustomField(updatedCustomfield) {
            const customField = this.customFields.find((x) => x.id == updatedCustomfield.id);
            const oldProperty = toCamelCase(customField.name);
            const newProperty = toCamelCase(updatedCustomfield.name);
            for (let tableItem of this.tableData.items) {
                tableItem.customFields[newProperty] = tableItem.customFields[oldProperty];
                delete tableItem.customFields[oldProperty];
            }

            customField.name = updatedCustomfield.name;
        },
        deleteCustomField(customFieldId) {
            const index = this.customFields.findIndex((x) => x.id == customFieldId);
            if (index < 0) throw new Error("Unable to delete the field because it does not exist");

            const customField = this.customFields[index];
            this.customFields.splice(index, 1);

            for (let tableItem of this.tableData.items) {
                delete tableItem.customFields[toCamelCase(customField.name)];
            }
        },
    },
};
