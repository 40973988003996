import qs from "qs";
import Vue from "vue";
import Router from "vue-router";
import EntityRoutes from "./entityRoutes";
import { getRoutes } from "@/features/schemas/services/schemaRouteProvider";
import securityService from "./services/securityService";

Vue.use(Router);

var routes = [
    {
        path: "/",
        name: "home",
        component: () => import("./views/Home.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./views/Login.vue"),
    },
    {
        path: "/shift-planning",
        name: "shiftPlanning",
        component: () => import("./shiftAllocation/ShiftAllocation.vue"),
    },
    {
        path: "/shift-review",
        name: "shiftReview",
        component: () => import("./shiftReview/ShiftReview.vue"),
        meta: { helpLink: "shiftReview" },
    },
    {
        path: "/shift-review-2",
        name: "shiftReview2",
        component: () => import("./shiftReview/ShiftReview2.vue"),
        meta: { helpLink: "shiftReview" },
    },
    {
        path: "/hazard-review",
        name: "hazardReview",
        component: () => import("./features/hazards/HazardReview.vue"),
    },
    {
        path: "/hazard-board",
        name: "hazardBoard",
        component: () => import("./features/hazards/HazardBoard.vue"),
    },
    {
        path: "/inspection-review",
        name: "inspectionReview",
        component: () => import("./features/inspectionReview/InspectionReview.vue"),
    },
    {
        path: "/inspection-approval/:id",
        name: "inspectionApproval",
        component: () => import("./features/inspectionReview/InspectionApproval.vue"),
    },
    {
        path: "/explosives-report",
        name: "explosivesReport",
        component: () => import("./features/explosives/ExplosivesReport.vue"),
        meta: { helpLink: "explosivesReport" },
    },
    {
        path: "/logs",
        name: "logs",
        component: () => import("./features/logs/Logs.vue"),
    },
    {
        path: "/shift-sheet",
        name: "shiftSheet",
        component: () => import("./shiftSheet/ShiftSheet.vue"),
    },
    {
        path: "/shift-sheet/print",
        name: "shiftSheetPrint",
        component: () => import("./shiftSheet/ShiftSheetPrint.vue"),
    },
    {
        path: "/equipment-review",
        name: "equipmentReview",
        component: () => import("./timeUsage/equipmentReview/EquipmentReview.vue"),
        meta: { helpLink: "equipmentReview" },
    },
    {
        path: "/time-usage-report",
        name: "timeUsageReport",
        component: () => import("./reports/timeUsages/TimeUsageReport.vue"),
    },
    {
        path: "/license",
        name: "license",
        component: () => import("./views/License.vue"),
    },
    {
        path: "/plodtrack",
        name: "plodtrack",
        component: () => import("./plodtrack/views/Plodtrack.vue"),
    },
    {
        path: "/loadscan",
        name: "loadscan",
        component: () => import("./loadscan/views/LoadScan.vue"),
    },
    {
        path: "/transcale",
        name: "transcale",
        component: () => import("./transcale/views/Transcale.vue"),
    },
    {
        path: "/minnovare",
        name: "minnovare",
        component: () => import("./minnovare/views/Minnovare.vue"),
    },
    {
        path: "/apisync",
        name: "apisync",
        component: () => import("./apisync/views/ApiSync.vue"),
    },
    {
        path: "/users",
        name: "users",
        component: () => import("./views/Users.vue"),
        meta: { helpLink: "users" },
    },
    {
        path: "/api-clients",
        name: "api-clients",
        component: () => import("./tableViews/ApiClients.vue"),
    },
    {
        path: "/checklist-tree",
        name: "checklistTree",
        component: () => import("./views/ChecklistTree"),
    },
    {
        path: "/master-router",
        name: "masterRouter",
        component: () => import("./views/MasterRouter.vue"),
        meta: { isMasterOnly: true },
    },
    {
        path: "/branding",
        name: "branding",
        component: () => import("./views/Branding.vue"),
    },
    {
        path: "/development-drill-standard/:id",
        name: "developmentDrillStandard",
        component: () => import("./editViews/DevelopmentDrillStandard.vue"),
    },
    {
        path: "/meter/:id",
        name: "meter",
        component: () => import("./editViews/Meter.vue"),
    },
    {
        path: "/production-drill-plan/:id",
        name: "productionDrillPlan",
        component: () => import("./editViews/ProductionDrillPlan.vue"),
    },
    {
        path: "/role/:id",
        name: "role",
        component: () => import("./editViews/Role.vue"),
    },
    {
        path: "/unit/:id",
        name: "unit",
        component: () => import("./editViews/Unit.vue"),
    },
    {
        path: "/consumable-group/:id",
        name: "consumableGroup",
        component: () => import("./editViews/ConsumableGroup.vue"),
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("./features/settings/views/Settings.vue"),
    },
    {
        path: "/email-settings",
        name: "emailSettings",
        component: () => import("./features/settings/views/EmailSettings.vue"),
        meta: { isMasterOnly: true },
    },
    {
        path: "/user-notifications/:userId",
        name: "userNotifications",
        component: () => import("./features/user/views/UserNotifications.vue"),
    },
    {
        path: "/daily-shift-reports",
        name: "dailyShiftReports",
        component: () =>
            import(/* webpackChunkName: "daily-shift-reports" */ "./reports/dailyShift/DailyShiftReports.vue"),
    },
    {
        path: "/daily-shift-report/:id",
        name: "dailyShiftReport",
        component: () =>
            import(/* webpackChunkName: "daily-shift-report" */ "./reports/dailyShift/DailyShiftReport.vue"),
    },
    {
        path: "/diamond-drill-report",
        name: "diamondDrillReport",
        component: () =>
            import(/* webpackChunkName: "diamond-drill-report" */ "./reports/diamondDrill/DiamondDrillReport.vue"),
    },
    {
        path: "/diamond-drill-bit-report",
        name: "diamondDrillBitReport",
        component: () =>
            import(
                /* webpackChunkName: "diamond-drill-bit-report" */ "./reports/diamondDrillBit/DiamondDrillBitReport.vue"
            ),
    },
    {
        path: "/meters-report",
        name: "metersReport",
        component: () => import(/* webpackChunkName: "meters-report" */ "./reports/meters/MetersReport.vue"),
    },
    {
        path: "/device-status-report",
        name: "deviceStatusReport",
        component: () =>
            import(/* webpackChunkName: "device-status-report" */ "./reports/deviceStatus/DeviceStatusReport.vue"),
    },
    {
        path: "/maintenance-report",
        name: "maintenanceReport",
        component: () =>
            import(/* webpackChunkName: "device-status-report" */ "./reports/maintenance/MaintenanceReport.vue"),
    },
    {
        path: "/operator-time-usage-report",
        name: "operatorTimeUsageReport",
        component: () =>
            import(
                /* webpackChunkName: "device-status-report" */ "./reports/operatorTimeUsage/OperatorTimeUsageReport.vue"
            ),
    },
    {
        path: "/location-time-usage-report",
        name: "locationTimeUsageReport",
        component: () =>
            import(
                /* webpackChunkName: "device-status-report" */ "./reports/mergedTimeUsage/locationTimeUsage/LocationTimeUsageReport.vue"
            ),
    },
    {
        path: "/location-network",
        name: "locationNetwork",
        component: () => import(/* webpackChunkName: "location-network-cytoscape" */ "./views/LocationNetwork.vue"),
    },
    {
        path: "/tkm-report",
        name: "tkmReport",
        component: () =>
            import(/* webpackChunkName: "tkm-report" */ "./reports/locationDistance/LocationDistanceReport.vue"),
    },
    {
        path: "/equipment-time-usage-report",
        name: "equipmentTimeUsageReport",
        component: () =>
            import(
                /* webpackChunkName: "device-status-report" */ "./reports/mergedTimeUsage/equipmentTimeUsage/EquipmentTimeUsageReport.vue"
            ),
    },
    {
        path: "/production-drill-report",
        name: "productionDrillReport",
        component: () =>
            import(
                /* webpackChunkName: "production-drill-report" */ "./reports/productionDrill/ProductionDrillReport.vue"
            ),
    },
    {
        path: "/production-drill-report/:id",
        name: "productionDrillPlanReport",
        component: () =>
            import(
                /* webpackChunkName: "production-drill-report" */ "./reports/productionDrill/ProductionDrillReport.vue"
            ),
    },
    {
        path: "/change-password",
        name: "changePassword",
        component: () => import(/* webpackChunkName: "change-password" */ "./views/ChangePassword.vue"),
    },
    {
        path: "/time-usage-category-mapping",
        name: "timeUsageCategoryMapping",
        component: () =>
            import(/* webpackChunkName: "time-usage-type" */ "./timeUsage/quickMapping/TimeUsageCategoryMapping.vue"),
    },

    // #region Face Sampling
    {
        path: "/face-sample-background-images",
        name: "faceSampleBackgroundImages",
        component: () =>
            import(
                /* webpackChunkName: "face-sample-background-images" */ "./tableViews/FaceSampleBackgroundImages.vue"
            ),
    },
    {
        path: "/face-sampling",
        name: "faceSampling",
        component: () => import(/* webpackChunkName: "face-sampling" */ "./faceSampling/main/FaceSampling.vue"),
    },
    {
        path: "/face-sampling/add",
        name: "faceSamplingAdd",
        component: () => import(/* webpackChunkName: "face-sampling-edit" */ "./faceSampling/FaceSamplingEdit.vue"),
    },
    {
        path: "/face-sampling/:id",
        name: "faceSamplingEdit",
        component: () => import(/* webpackChunkName: "face-sampling-edit" */ "./faceSampling/FaceSamplingEdit.vue"),
    },
    // #endregion
    {
        path: "/record-history-test",
        name: "recordHistoryTest",
        component: () => import(/* webpackChunkName: "action-logs" */ "./actionLogs/RecordHistoryTest.vue"),
    },
    {
        path: "/item-order-batch/:id",
        name: "itemOrderBatch",
        component: () =>
            import(/* webpackChunkName: "item-order-batch" */ "./features/exmOrdering/ItemOrderTablePage.vue"),
    },
    // otherwise redirect to home
    {
        path: "*",
        redirect: "/",
    },
]
    .concat(EntityRoutes)
    .concat(getRoutes());

// Pass the query string and route parameters as props into all routes.
routes.forEach(addProps);

function addProps(r) {
    r.props = (route) => ({
        ...route.params,
        ...route.query,
        ...r.staticProps,
    });

    r.children?.forEach(addProps);
}

function parseQuery(query) {
    return qs.parse(query);
}

function stringifyQuery(query) {
    let result = qs.stringify(query, { encode: false });
    return result ? "?" + result : "";
}

const router = new Router({
    mode: "history",
    parseQuery,
    stringifyQuery,
    routes: routes,
});

router.beforeEach(async (to, from, next) => {
    if (!to.meta.isMasterOnly || (await securityService.isMasterUser())) {
        next();
    } else {
        next("/");
    }
});

export default router;
