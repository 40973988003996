<template>
    <v-container fluid>
        <v-row>
            <!-- Left Column -->
            <v-col class="pa-2 flex-grow-1">
                <mode-banner :mode="selectedMode" />

                <v-row class="my-0">
                    <v-col class="d-flex py-2">
                        <div class="grid-container">
                            <v-chip v-show="selectedCategory" class="selected-category">
                                <span v-if="selectedCategory?.name">{{ selectedCategory?.name }}</span>
                                <span v-if="selectedDetonator">
                                    &nbsp;&nbsp;
                                    <strong>{{ selectedDetonator.Label }}</strong>
                                </span>
                            </v-chip>

                            <face-sheet-grid
                                ref="faceSheetGrid"
                                v-model="detonators"
                                :selectedDetonator="selectedDetonator"
                                :mode="selectedMode"
                                @add="addDetonator"
                                @edit="editDetonator"
                                @delete="deleteDetonator"
                                @batchUpdate="batchUpdateDetonator"
                                @historyStateChanged="onHistoryStateChanged"
                            />

                            <state-control
                                :canUndo="!isSaving && canUndo"
                                :canRedo="!isSaving && canRedo"
                                @undo="onUndo"
                                @redo="onRedo"
                            />

                            <loading-overlay :zIndex="100" :opacity="0.5" absolute :loading="isSaving">
                                {{ saveMessage }}
                            </loading-overlay>
                        </div>
                    </v-col>
                </v-row>

                <detonator-control
                    :selected-detonator.sync="selectedDetonator"
                    :selected-category.sync="selectedCategory"
                    :detonator-categories="detonatorCategories"
                    :magazine-items="magazineItems"
                    :selected-mode.sync="selectedMode"
                    :used-magazine-item-ids="usedMagazineItemIds"
                    :used-magazine-item-category-ids="usedMagazineItemCategoryIds"
                />
            </v-col>

            <!-- Right Column -->
            <v-col class="pa-2 flex-grow-1">
                <div class="pb-1 font-weight-medium">Detonators</div>
                <detonator-table
                    :standard-items="standardItems"
                    :magazine-items="magazineItems"
                    :magazine-item-categories="magazineItemCategories"
                    @updateSpare="onUpdateSpare"
                />
            </v-col>

            <loading-overlay :zIndex="100" :opacity="0.5" absolute :loading="isLoading" />
        </v-row>
    </v-container>
</template>

<script>
    import { InteractionMode } from "face-sheet-grid";
    import { list, add, update, delete_, deleteMany, undelete, get } from "@/features/schemas/services/schemaApi";
    import { equal } from "@/services/filtering";
    import { v4 as uuidv4 } from "uuid";

    import FaceSheetGrid from "./FaceSheetGrid.vue";
    import DetonatorControl from "./DetonatorControl.vue";
    import ModeBanner from "./ModeBanner.vue";
    import DetonatorTable from "./DetonatorTable.vue";
    import LoadingOverlay from "@/components/LoadingOverlay.vue";
    import StateControl from "./StateControl.vue";
    export default {
        components: { FaceSheetGrid, DetonatorControl, ModeBanner, DetonatorTable, LoadingOverlay, StateControl },
        data() {
            return {
                isLoading: false,
                isSaving: false,
                saveMessage: "Saving",
                detonators: [],
                magazineItems: [],
                detonatorCategories: [],
                magazineItemTypes: [],
                magazineItemCategories: [],
                standardItems: [],
                selectedDetonator: {},
                selectedCategory: null,
                selectedMode: InteractionMode.Normal,
                canUndo: false,
                canRedo: false,
            };
        },
        props: {
            standardId: null,
        },
        async mounted() {
            this.isLoading = true;
            await this.fetchData();
            this.setDetonatorCategories();
            await this.getSetStandardItems();
            this.isLoading = false;

            await this.$nextTick();
            const faceSheetGrid = this.$refs.faceSheetGrid;
            if (faceSheetGrid) {
                faceSheetGrid.$el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            }
        },
        methods: {
            createDetonators() {
                return this.standardItems.flatMap((item) => {
                    const coordinates = item.coordinates ? JSON.parse(item.coordinates) : [];
                    const magazineItem = this.magazineItems.find((m) => m.id === item.magazineItemId);

                    return coordinates.map((coord) => ({
                        Id: coord.id,
                        StandardItemId: item.id,
                        MagazineItemId: item.magazineItemId,
                        Label: magazineItem?.name || "Unknown",
                        Color: magazineItem?.icon?.backgroundColor || "black",
                        X: coord.x,
                        Y: coord.y,
                    }));
                });
            },
            setDetonatorCategories() {
                const uniqueCategoryIds = new Set();

                this.magazineItems.forEach((item) => {
                    const category = this.magazineItemCategories.find((x) => x.id == item.magazineItemCategoryId);

                    if (!category || !category.isShowIcon || item.name.Length > 3) return;

                    const itemType = this.magazineItemTypes.find((x) => x.id == item.magazineItemTypeId);
                    if (!itemType) return;

                    if (itemType.magazineType == "detonators" && !uniqueCategoryIds.has(category.id)) {
                        this.detonatorCategories.push(category);
                        uniqueCategoryIds.add(category.id);
                    }
                });
            },
            async refreshItems() {
                await this.getSetStandardItems();
            },
            async getSetStandardItems() {
                const standardItemQuery = await list("chargeStandardItem", {
                    filter: equal("chargeStandardId", this.standardId, "guid"),
                    sortBy: null,
                    direction: "descending",
                    recordState: "active",
                });
                this.standardItems = standardItemQuery.items;
                this.detonators = this.createDetonators();
            },
            async fetchData() {
                const [magazineItemQuery, categoryQuery, itemTypesQuery] = await Promise.all([
                    list("magazineItem", {
                        filter: null,
                        sortBy: "priority",
                        direction: "descending",
                        recordState: "active",
                    }),
                    list("magazineItemCategory", {
                        filter: null,
                        sortBy: "priority",
                        direction: "descending",
                        recordState: "active",
                    }),
                    list("magazineItemType", {
                        filter: null,
                        sortBy: "priority",
                        direction: "descending",
                        recordState: "active",
                    }),
                ]);

                this.magazineItems = magazineItemQuery.items;
                this.magazineItemCategories = categoryQuery.items;
                this.magazineItemTypes = itemTypesQuery.items;
            },
            onHistoryStateChanged({ canUndo, canRedo }) {
                this.canUndo = canUndo ?? false;
                this.canRedo = canRedo ?? false;
            },
            async onUpdateSpare(standardItem) {
                const data = await update("chargeStandardItem", standardItem);
                if (data) {
                    const index = this.standardItems.findIndex((item) => item.id === standardItem.id);
                    this.standardItems.splice(index, 1, standardItem);
                    this.detonators = this.createDetonators();
                }
            },
            //#region Detonator Functions
            async addDetonator(point) {
                const existingItem = this.standardItems.find((x) => x.magazineItemId == this.selectedDetonator.Id);

                if (existingItem) {
                    const localCoords = JSON.parse(existingItem.coordinates);
                    localCoords.push({ id: uuidv4(), x: point.x, y: point.y });
                    existingItem.coordinates = JSON.stringify(localCoords);
                    existingItem.quantity = localCoords.length;

                    const data = await update("chargeStandardItem", existingItem);
                    if (data) {
                        const index = this.standardItems.findIndex((item) => item.id == data.id);
                        this.standardItems.splice(index, 1, data);
                        this.detonators = this.createDetonators();
                        await this.$nextTick();
                        this.$refs.faceSheetGrid?.addHistory();
                    }
                } else {
                    const newStandardItem = {
                        MagazineItemId: this.selectedDetonator.Id,
                        Quantity: 1,
                        Coordinates: JSON.stringify([{ id: uuidv4(), x: point.x, y: point.y }]),
                        Spare: null,
                        ChargeStandardId: this.standardId,
                    };

                    const data = await add("chargeStandardItem", newStandardItem);
                    if (data) {
                        this.standardItems.push(data);

                        this.detonators = this.createDetonators();
                        await this.$nextTick();
                        this.$refs.faceSheetGrid?.addHistory();
                    }
                }
            },
            async editDetonator(detonator) {
                const existingStandardItem = this.standardItems.find((x) => x.id === detonator.StandardItemId);

                if (existingStandardItem) {
                    const coordinates = JSON.parse(existingStandardItem.coordinates);

                    const coordIndex = coordinates.findIndex((coord) => coord.id === detonator.Id);
                    if (coordIndex !== -1) {
                        coordinates[coordIndex] = {
                            id: detonator.Id,
                            x: detonator.X,
                            y: detonator.Y,
                        };

                        existingStandardItem.coordinates = JSON.stringify(coordinates);

                        const data = await update("chargeStandardItem", existingStandardItem);
                        if (data) {
                            const index = this.standardItems.findIndex((item) => item.id === data.id);
                            this.standardItems.splice(index, 1, data);
                            this.detonators = this.createDetonators();
                            await this.$nextTick();
                            this.$refs.faceSheetGrid?.addHistory();
                        }
                    }
                }
            },
            async deleteDetonator(detonator) {
                const existingItem = this.standardItems.find((item) => item.id === detonator.StandardItemId);
                if (!existingItem) return;

                const coordinates = JSON.parse(existingItem.coordinates);
                const updatedCoordinates = coordinates.filter((coord) => coord.id !== detonator.Id);

                if (updatedCoordinates.length === 0) {
                    await delete_("chargeStandardItem", existingItem.id);
                    const index = this.standardItems.findIndex((item) => item.id === existingItem.id);
                    this.standardItems.splice(index, 1);
                } else {
                    existingItem.coordinates = JSON.stringify(updatedCoordinates);
                    existingItem.quantity = updatedCoordinates.length;

                    const data = await update("chargeStandardItem", existingItem);
                    if (data) {
                        const index = this.standardItems.findIndex((item) => item.id === existingItem.id);
                        this.standardItems.splice(index, 1, data);
                    }
                }
                this.detonators = this.createDetonators();
                await this.$nextTick();
                this.$refs.faceSheetGrid?.addHistory();
            },
            async batchUpdateDetonator(type, detonatorsToUpdate, detonatorsToDelete) {
                if (detonatorsToUpdate.length === 0 && detonatorsToDelete.length === 0) return;

                this.isSaving = true;

                switch (type) {
                    case "undo":
                        this.saveMessage = "Undoing";
                        break;
                    case "redo":
                        this.saveMessage = "Redoing";
                        break;
                    default:
                        this.saveMessage = "Saving";
                        break;
                }

                const standardItemsMap = this.initializeStandardItemsMap(detonatorsToUpdate, detonatorsToDelete);

                try {
                    await this.processDeletes(detonatorsToDelete, standardItemsMap);
                    await this.processUpdates(detonatorsToUpdate, standardItemsMap);
                    await this.saveBatchUpdate(standardItemsMap);

                    this.detonators = this.createDetonators();

                    // If the batch update wasn't triggered by undo/redo actions, then we need to add a history state.
                    if (type === "batchEdit") {
                        await this.$nextTick();
                        this.$refs.faceSheetGrid?.addHistory();
                    }
                } finally {
                    this.isSaving = false;
                }
            },

            initializeStandardItemsMap(detonatorsToUpdate, detonatorsToDelete) {
                const relevantIds = new Set([
                    ...detonatorsToUpdate.map((det) => det.StandardItemId),
                    ...detonatorsToDelete.map((det) => det.StandardItemId),
                ]);

                const standardItemsMap = new Map();

                for (const item of this.standardItems) {
                    if (!relevantIds.has(item.id)) continue;

                    const coordinates = JSON.parse(item.coordinates);
                    if (!coordinates) continue;

                    standardItemsMap.set(item.id, {
                        item: { ...item },
                        coordinates,
                    });
                }

                return standardItemsMap;
            },

            async processDeletes(detonatorsToDelete, standardItemsMap) {
                if (!detonatorsToDelete.length) return;

                const standardItemsToDelete = new Set();

                for (const detToDelete of detonatorsToDelete) {
                    const standardItemData = standardItemsMap.get(detToDelete.StandardItemId);
                    if (!standardItemData) continue;

                    standardItemData.coordinates = standardItemData.coordinates.filter(
                        (coord) => coord.id !== detToDelete.Id
                    );

                    if (standardItemData.coordinates.length === 0) {
                        standardItemsToDelete.add(detToDelete.StandardItemId);
                        standardItemsMap.delete(detToDelete.StandardItemId);
                    }
                }

                if (standardItemsToDelete.size === 0) return;

                const deleteIds = Array.from(standardItemsToDelete);
                await deleteMany("chargeStandardItem", deleteIds);
                this.standardItems = this.standardItems.filter((x) => !deleteIds.includes(x.id));
            },

            async processUpdates(detonatorsToUpdate, standardItemsMap) {
                if (!detonatorsToUpdate.length) return;

                for (const updatedDet of detonatorsToUpdate) {
                    let standardItemData = standardItemsMap.get(updatedDet.StandardItemId);

                    if (!standardItemData) {
                        standardItemData = this.createNewStandardItemData(updatedDet);
                        standardItemsMap.set(updatedDet.StandardItemId, standardItemData);
                    }

                    this.updateCoordinates(standardItemData, updatedDet);
                }
            },

            createNewStandardItemData(updatedDet) {
                const existingItem = this.standardItems.find((item) => item.id === updatedDet.StandardItemId);

                if (existingItem) {
                    return {
                        item: { ...existingItem },
                        coordinates: JSON.parse(existingItem.coordinates) || [],
                    };
                }

                return {
                    item: {
                        id: updatedDet.StandardItemId,
                        magazineItemId: updatedDet.MagazineItemId,
                        chargeStandardId: this.standardId,
                    },
                    coordinates: [],
                };
            },

            updateCoordinates(standardItemData, updatedDet) {
                const coordIndex = standardItemData.coordinates.findIndex((coord) => coord.id === updatedDet.Id);

                const newCoord = {
                    id: updatedDet.Id,
                    x: updatedDet.X,
                    y: updatedDet.Y,
                };

                if (coordIndex !== -1) {
                    standardItemData.coordinates[coordIndex] = newCoord;
                } else {
                    standardItemData.coordinates.push(newCoord);
                }
            },

            async saveBatchUpdate(standardItemsMap) {
                for (const data of standardItemsMap.values()) {
                    let item = {
                        ...data.item,
                        coordinates: JSON.stringify(data.coordinates),
                        quantity: data.coordinates.length,
                    };

                    const isPreviousItem = !this.standardItems.some((existingItem) => existingItem.id === data.item.id);

                    // If the item does not exist, then it was previously deleted
                    // We need to "undelete" the item if actioning undo/redo.
                    if (isPreviousItem) {
                        await undelete("chargeStandardItem", item.id);
                        item = await get("chargeStandardItem", item.id);
                    } else {
                        item = await update("chargeStandardItem", item);
                    }

                    this.updateStandardItems(item);
                }
            },

            updateStandardItems(result) {
                const index = this.standardItems.findIndex((item) => item.id === result.id);
                if (index !== -1) {
                    this.standardItems.splice(index, 1, result);
                } else {
                    this.standardItems.push(result);
                }
            },
            //#endregion Detonator Functions

            onUndo() {
                this.$refs.faceSheetGrid?.undo();
            },
            onRedo() {
                this.$refs.faceSheetGrid?.redo();
            },
        },
        computed: {
            usedMagazineItemIds() {
                return this.standardItems.map((x) => x.magazineItemId);
            },
            usedMagazineItemCategoryIds() {
                if (!this.magazineItems || !this.usedMagazineItemIds) return [];

                const items = this.magazineItems.filter((x) => this.usedMagazineItemIds.includes(x.id));
                const categoryIds = items.map((x) => x.magazineItemCategoryId);
                return [...new Set(categoryIds)];
            },
        },
    };
</script>
<style scoped>
    .v-container {
        max-width: 100%;
        min-height: 100vh;
    }

    .left-column,
    .right-column {
        height: 100%;
        max-width: 50%;
        overflow: hidden;
    }

    .grid-container {
        position: relative;
        margin: 0 auto;
        height: 100%;
        border-radius: 8px;
        background: #003a49;
    }

    .selected-category {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        margin: 8px auto;
        width: fit-content;
        color: white !important;
        background: rgba(0, 0, 0, 0.5) !important;
        z-index: 1;
    }

    ::v-deep .loading {
        color: white;
    }
</style>
